import { Fragment, useRef } from 'react';
import { Dialog, Transition } from '@headlessui/react';

import CloseIcon from '../../components/Icons/CloseIcon';
import Card from '../../components/Card/Card';

function getSourceSystemID(customer, source_system_name) {
  let sourceSystemID;
  if (customer &&
    customer.source_ids &&
    customer.source_ids.length > 0) {
    customer.source_ids.map((css) => {
      if (css &&
        css.source_system &&
        css.source_system.name &&
        css.source_system.name === source_system_name) {
        sourceSystemID = css.external_id;
      }
      return true;
    });
  }
  return sourceSystemID;
}

function getMedicalID(customer) {
  let medicalID;
  if (customer &&
    customer.identity_documents &&
    customer.identity_documents.length > 0) {
    customer.identity_documents.map((cid) => {
      if (cid &&
        cid.Type &&
        cid.Type === 1) {
        medicalID = cid.identifier;
      }
      return true;
    });
  }
  return medicalID;
}

function formatDOB(dob) {
  const theDate = new Date(dob);
  const enUSFormatter = new Intl.DateTimeFormat('en-US');
  return enUSFormatter.format(theDate);
}

function getCustomerTypeLabel(customerTypeID) {
  let customerTypeLabel;

  switch (customerTypeID) {
    case 0:
      customerTypeLabel = 'Standard';
      break;
    case 1:
      customerTypeLabel = 'Rec';
      break;
    case 2:
      customerTypeLabel = 'Med';
      break;
    case 3:
      customerTypeLabel = 'RecAndMed';
      break;
    case 4:
      customerTypeLabel = 'Business';
      break;
    default:
      customerTypeLabel = 'Standard';
      break;
  }

  return customerTypeLabel;
}

const CustomerModal = ({
  showModal,
  setShowModal,
  setModalCustomer,
  customer,
}) => {
  const cancelButtonRef = useRef(null);

  return (
    <Transition.Root show={showModal} as={Fragment}>
      <Dialog
        as='div'
        static
        className='fixed z-10 inset-1 md:top-20 lg:top-1 overflow-y-auto'
        initialFocus={cancelButtonRef}
        open={showModal}
        onClose={() => {
          setShowModal(false);
          setModalCustomer(null);
        }}
      >
        {/* Overlay Container */}
        <div className='flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0'>
          <Transition.Child
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0'
            enterTo='opacity-100'
            leave='ease-in duration-300'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
          >
            <Dialog.Overlay className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity' />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className='hidden sm:inline-block sm:align-middle sm:h-screen'
            aria-hidden='true'
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
            enterTo='opacity-100 translate-y-0 sm:scale-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100 translate-y-0 sm:scale-100'
            leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
          >
            {/* Modal Container */}
            <div className='inline-block align-bottom rounded-lg text-left shadow-xl transform transition-all sm:align-middle max-w-full md:w-11/12 md:max-w-2xl lg:max-w-6xl my-20 md:mt-0 mb-auto max-w'>
              <div className='bg-white px-4 py-3 sm:px-6'>
                <div className='flex text-base border-b-2 border-gray-200 text-center mx-auto'>
                  <h2 className='text-center font-medium text-gray-500 uppercase tracking-wider py-1 md:text-2xl'>
                    Customer Details
                  </h2>
                  <div
                    className='ml-auto'
                    onClick={() => {
                      setShowModal(false);
                      setModalCustomer(null);
                    }}
                    ref={cancelButtonRef}
                  >
                    <CloseIcon styles='text-gray-700 hover:text-green-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500' />
                  </div>
                </div>
              </div>
              <div className='bg-white overflow-auto'>
                <div className='grid md:grid-rows-1 md:grid-cols-2 gap-6 px-3 pb-3'>
                  {/* Card 1 */}
                  <Card title='Customer' styles='col-span-full'>
                    <div className='py-2'>
                      <span className='ml-5'><b>First Name:</b> {customer.first_name}</span>
                    </div>
                    <div className="py-2">
                      <span className='ml-5'><b>Last Name:</b> {customer.last_name}</span>
                    </div>
                    <div className="py-2">
                      <span className='ml-5'><b>Email:</b> {customer.email}</span>
                    </div>
                    <div className="py-2">
                      <span className='ml-5'><b>D.O.B:</b> {formatDOB(customer.dob)}</span>
                    </div>
                    <div className="py-2">
                      <span className='ml-5'><b>Customer Type:</b> {getCustomerTypeLabel(customer.customer_type_id)}</span>
                    </div>
                    <div className="py-2">
                      <span className='ml-5'><b>Delivery Eligible:</b> {customer.delivery_eligible ? 'True' : 'False'}</span>
                    </div>
                    <div className="py-2">
                      <span className='ml-5'><b>Medical ID:</b> {getMedicalID(customer)}</span>
                    </div>
                    <div className="py-2">
                      <span className='ml-5'><b>Magento ID:</b> {getSourceSystemID(customer, 'Magento')}</span>
                    </div>
                    <div className="py-2">
                      <span className='ml-5'><b>LeafLogix ID:</b> {getSourceSystemID(customer, 'Leaflogix')}</span>
                    </div>
                    <div className="py-2">
                      <span className='ml-5'><b>Customer ID:</b> {customer.id}</span>
                    </div>
                    <div className="py-2">
                      <span className='ml-5'><b>Created At:</b> {customer.created_at}</span>
                    </div>
                  </Card>
                </div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default CustomerModal;
