import React, { useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useOktaAuth } from '@okta/okta-react';

import FacilityInventoryListItem, {
	FacilityInventoryListItemSkeleton,
} from '../FacilityInventoryListItem/FacilityInventoryListItem';
import {
	fetchInventoryForFacility,
	fetchMoreInventoryForFacility,
	setFacility,
	setInitialState,
	setInventoryMsg,
} from '../../inventoryState';
import {
	selectFacilityInventoryView,
	views,
} from '../../../SearchBar/searchBarState';
import LeftArrowIcon from '../../../../components/Icons/LeftArrowIcon';
import InventoryItemModal from '../InventoryItemModal/Modal';
import useIntersectionObserver from '../../../../util/intersectionObserver';

//This component renders a list of a particular facility's inventory.
const FacilityInventoryList = () => {
	const history = useHistory();
	const dispatch = useDispatch();
	const { authState } = useOktaAuth();

	//Getting state from redux
	const facility = history?.location?.state?.facility;
	const inventory = useSelector((state) => state.inventory.inventory);
	const inventoryPageCount = useSelector(
		(state) => state.inventory.inventory?.meta?.next_page
	);
	const inventoryMsg = useSelector((state) => state.inventory.inventoryMsg);
	const isInventoryLoading = useSelector(
		(state) => state.inventory.isInventoryLoading
	);
	const isMoreInventoryLoading = useSelector(
		(state) => state.inventory.isMoreInventoryLoading
	);
	const currentView = useSelector((state) => state.searchBar.view);
	const [modalItem, setModalItem] = useState(null);
	const [showModal, setShowModal] = useState(false);

	const renderModal = (inventoryItem) => {
		setShowModal(true);
		setModalItem(inventoryItem);
	};

	//Renders the inventory items.
	const inventoryList = inventory?.results?.map((inventoryItem) => (
		<FacilityInventoryListItem
			inventoryItem={inventoryItem}
			renderModal={() => renderModal(inventoryItem)}
			key={inventoryItem.item.id}
		/>
	));

	const renderSkeletonItems = (x) => {
		let skeletonItems = [];
		for (let i = 0; i < x; i++) {
			skeletonItems.push(<FacilityInventoryListItemSkeleton key={i} />);
		}
		return skeletonItems;
	};

	useEffect(() => {
		if (currentView !== views.FACILITY_INVENTORY) {
			dispatch(selectFacilityInventoryView());
		}
		dispatch(setFacility(facility));
		dispatch(fetchInventoryForFacility(authState?.idToken));
		return () => dispatch(setInitialState());
	}, [dispatch, authState?.idToken, facility, currentView]);

	useIntersectionObserver({
		target: '.endOfItemsList',
		handleInViewPort: () => {
			dispatch(fetchMoreInventoryForFacility(authState?.idToken));
			if (inventoryPageCount === null) {
				dispatch(setInventoryMsg('NO MORE RESULTS'));
			}
		},
	});

	//TODO Create an alert pop up that explains why you got redirected.
	if (facility === undefined) {
		return <Redirect to='/' />;
	}

	return (
		<div className=''>
			{showModal && (
				<InventoryItemModal
					item={modalItem}
					showModal={showModal}
					setShowModal={setShowModal}
					setModalItem={setModalItem}
				/>
			)}
			<div className='flex'>
				<button
					onClick={() => history.goBack()}
					className='mr-4 text-gray-400 hover:text-green-400'
				>
					<LeftArrowIcon />
				</button>
				<h1 className='text-2xl font-sans text-gray-600 font-light'>{`Inventory at ${facility.name}`}</h1>
			</div>

			<div className='mt-5'>
				<div className='flex flex-col'>
					<div className='-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8'>
						<div className='py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8'>
							<div className='shadow overflow-hidden border-b border-gray-200'>
								<table className='min-w-full divide-y divide-gray-200'>
									<thead className='bg-gray-50 font-sans'>
										<tr>
											<th
												scope='col'
												className='px-6 py-3 text-left text-xs font-light text-gray-500 uppercase tracking-wider'
											>
												Product Name
											</th>
											<th
												scope='col'
												className='py-3 text-xs font-light text-gray-500 uppercase tracking-wider text-center'
											>
												Brand
											</th>
											<th
												scope='col'
												className='py-3 text-xs font-light text-gray-500 uppercase tracking-wider text-center'
											>
												Quantity
											</th>
											<th
												scope='col'
												className='py-3 text-xs font-light text-gray-500 uppercase tracking-wider text-center'
											>
												Threshold
											</th>
											<th
												scope='col'
												className='py-3 text-xs font-light text-gray-500 uppercase tracking-wider text-center'
											>
												Status
											</th>
											<th
												scope='col'
												className='py-3 text-xs font-light text-gray-500 uppercase tracking-wider text-center'
											>
												Part #
											</th>

											<th
												scope='col'
												className='py-3 text-xs font-light text-gray-500 uppercase tracking-wider text-center'
											>
												Flourish ID
											</th>

											<th
												scope='col'
												className='px-6 py-3 text-xs font-light text-gray-500 uppercase tracking-wider text-right'
											>
												Item Details
											</th>
										</tr>
									</thead>
									<tbody className='bg-white divide-y divide-gray-200'>
										{isInventoryLoading
											? renderSkeletonItems(30)
											: inventoryList}
										{isMoreInventoryLoading ? renderSkeletonItems(3) : null}
									</tbody>
								</table>
							</div>
							<div className='endOfItemsList'>
								<div className='mt-5 text-center text-gray-500 uppercase'>
									{inventoryMsg}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default FacilityInventoryList;
