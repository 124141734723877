import { useOktaAuth } from '@okta/okta-react';
import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { fetchFacilities, filterFacilities } from '../facilitiesState';
import {
	selectInventoryByFacilityView,
	views,
	setSearch,
} from '../../../SearchBar/searchBarState';
import SearchBar from '../../../SearchBar/SearchBar';
import FacilityListItem, {
	FacilityListItemSkeleton,
} from '../FacilityListItem/FacilityListItem';
import { COMMON_QUERY_PARAM_OPTIONS } from '../../../../util/constants';

const FacilityList = () => {
	const { authState } = useOktaAuth();
	const { idToken } = authState;
	const dispatch = useDispatch();
	const facilities = useSelector((state) => state.facilities.facilities);
	const filteredFacilities = useSelector(
		(state) => state.facilities.filteredFacilities
	);
	const currentView = useSelector((state) => state.searchBar.view);
	const facilitiesMsg = useSelector((state) => state.facilities.facilitiesMsg);
	const isFacilitiesLoading = useSelector(
		(state) => state.facilities.isFacilitiesLoading
	);
	const searchQuery = useSelector((state) => state.searchBar.searchQuery);

	const renderFacilityListItems = filteredFacilities?.results?.map(
		(facility) => <FacilityListItem facility={facility} key={facility.id} />
	);

	const renderSkeletonItems = (idx) => {
		let skeletonItems = [];

		for (let i = 0; i < idx; i++) {
			skeletonItems.push(<FacilityListItemSkeleton key={i} />);
		}
		return skeletonItems;
	};

	//Filters the the facilities based on the search input, and displays that new filtered list.
	const handleInventoryByFacilitySearch = (e) => {
		if (e.target.value === '') {
			dispatch(filterFacilities(''));
		} else {
			dispatch(filterFacilities(e.target.value.toLowerCase()));
		}
	};

	useEffect(() => {
		//Prevents refetching (and resetting the facilities list) if the facilities list state is already populated.
		if (facilities?.results.length <= 0) {
			dispatch(fetchFacilities(idToken));
		}
	}, [dispatch, idToken, facilities, searchQuery]);

	useEffect(() => {
		if (currentView !== views.INVENTORY_BY_FACILITY) {
			dispatch(selectInventoryByFacilityView());
			dispatch(setSearch(''));
		}
	}, [dispatch, currentView]);

	return (
		<div className='overflow-x-hidden'>
			<div className='mx-2'>
				<SearchBar
					handleSearch={handleInventoryByFacilitySearch}
					placeholder='Search Facilities...'
					queryParamOptions={[COMMON_QUERY_PARAM_OPTIONS[0]]}
				/>
			</div>
			<div className='flex flex-col'>
				<div className='-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8'>
					<div className='py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8'>
						<div className='shadow overflow-hidden border-b border-gray-200'>
							<table className='min-w-full divide-y divide-gray-200'>
								<thead className='bg-gray-50'>
									<tr>
										<th
											scope='col'
											className='px-6 py-3 text-left text-xs font-light text-gray-500 uppercase tracking-wider'
										>
											Facility Name
										</th>
										<th
											scope='col'
											className='py-3 text-center text-xs font-light text-gray-500 uppercase tracking-wider'
										>
											Facility Type
										</th>
										<th
											scope='col'
											className='py-3 text-center text-xs font-light text-gray-500 uppercase tracking-wider'
										>
											City
										</th>
										<th
											scope='col'
											className='py-3 text-center text-xs font-light text-gray-500 uppercase tracking-wider'
										>
											State
										</th>
										<th
											scope='col'
											className='px-6 py-3 text-center text-xs font-light text-gray-500 uppercase tracking-wider'
										>
											Email
										</th>
										<th
											scope='col'
											className='px-6 py-3 text-xs font-light text-gray-500 uppercase tracking-wider'
										>
											Facility Details
										</th>
									</tr>
								</thead>
								<tbody className='bg-white divide-y divide-gray-200'>
									{isFacilitiesLoading && renderSkeletonItems(30)}
									{!isFacilitiesLoading && renderFacilityListItems}
								</tbody>
							</table>
						</div>
						<h4
							className='mt-5 text-center text-gray-500 uppercase'
							key={'facility-end'}
						>
							{facilitiesMsg}...
						</h4>
					</div>
				</div>
			</div>
		</div>
	);
};

export default FacilityList;
