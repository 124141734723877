import { useOktaAuth } from '@okta/okta-react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { Disclosure, Transition } from '@headlessui/react';
import {
	ClipboardListIcon,
	CreditCardIcon,
	DocumentTextIcon,
	UserIcon,
} from '@heroicons/react/outline';

import {
	selectInventoryByFacilityView,
	setSearch,
} from '../../features/SearchBar/searchBarState';
import { selectItem, PATHS } from './navigationbarState';

import ParallelLogo from '../ParallelLogo/ParallelLogo';
import { useEffect } from 'react';

const InventoryIcon = (
	<ClipboardListIcon
		className='mr-3 flex-shrink-0 h-6 w-6 text-gray-600 group-hover:text-gray-500'
		aria-hidden='true'
	/>
);

const PaymentsIcon = (
	<CreditCardIcon
		className='mr-3 flex-shrink-0 h-6 w-6 text-gray-600 group-hover:text-gray-500'
		aria-hidden='true'
	/>
);

const OrdersIcon = (
	<DocumentTextIcon
		className='mr-3 flex-shrink-0 h-6 w-6 text-gray-600 group-hover:text-gray-500'
		aria-hidden='true'
	/>
);

const CustomerIcon = (
	<UserIcon
		className='mr-3 flex-shrink-0 h-6 w-6 text-gray-600 group-hover:text-gray-500'
		aria-hidden='true'
	/>
);

export default function NavigationBar({ handleClick }) {
	const { oktaAuth, authState } = useOktaAuth();
	const dispatch = useDispatch();
	const userName = authState?.idToken.claims.name;
	const location = useLocation();
	let selectedItem = useSelector((state) => state.navigation.selectedItem);

	function clickHandler() {
		if (handleClick !== undefined) {
			handleClick();
		}
	}

	useEffect(() => {
		dispatch(selectItem(location.pathname));
	});

	return (
		<div className='flex flex-col flex-grow border-r border-gray-200 pt-5 pb-4 parallel-gradient overflow-y-auto'>
			<div className='flex items-center flex-shrink-0 px-5'>
				<div className='w-40'>
					<Link
						to='/'
						className='w-36 mx-auto cursor-pointer'
						onClick={() => {
							dispatch(selectInventoryByFacilityView());
							dispatch(setSearch(''));
							clickHandler();
						}}
					>
						<ParallelLogo />
					</Link>
				</div>
			</div>
			<div className='mt-5 flex-grow flex flex-col'>
				<nav className='flex-1 px-2 space-y-1' aria-label='Sidebar'>
					<NavItemWithChildren name='Inventory' icon={InventoryIcon}>
						<NavItemChild
							name={'Inventory By Facility'}
							path={PATHS.FACILITY_INVENTORY}
							handleClick={clickHandler}
						/>
						<NavItemChild
							name={'Inventory By Item'}
							path={PATHS.ITEM_INVENTORY}
							handleClick={clickHandler}
						/>
					</NavItemWithChildren>

					<NavItem
						name='Payments'
						path={PATHS.PAYMENTS}
						icon={PaymentsIcon}
						selectedItem={selectedItem}
						handleClick={clickHandler}
					/>
					<NavItem
						name='Orders'
						path={PATHS.ORDERS}
						icon={OrdersIcon}
						selectedItem={selectedItem}
						handleClick={clickHandler}
					/>
					<NavItem
						name='Customers'
						path={PATHS.CUSTOMERS}
						icon={CustomerIcon}
						selectedItem={selectedItem}
						handleClick={clickHandler}
					/>
				</nav>

				<div className='mt-auto px-5'>
					{/* //TODO Implement Dark Mode */}
					{/* <p className="mb-1 text-base">Dark Mode</p>
					<OptionSwitch label='' /> */}
					<div className='flex flex-col mt-5'>
						<div className='text-base'>
							Logged in as: <span className='font-bold'>{userName}</span>
						</div>
						<div
							onClick={() => oktaAuth.signOut()}
							className='text-base underline mt-3 text-right cursor-pointer'
						>
							Log out
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

function NavItem({ path, name, icon, selectedItem, handleClick }) {
	function classNames(...classes) {
		return classes.filter(Boolean).join(' ');
	}

	let current = selectedItem === path ? true : false;

	return (
		<div>
			<Link
				to={path}
				className={classNames(
					current
						? 'bg-shadow text-gray-900'
						: ' text-gray-800 hover:bg-shadow hover:text-gray-900',
					'group w-full flex items-center pl-2 pr-1 py-2 text-left text-sm font-medium rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500'
				)}
				onClick={handleClick}
			>
				<span
					className={
						(current
							? 'text-gray-900'
							: 'text-gray-700 group-hover:text-gray-500',
						'mr-3 flex-shrink-0 h-6 w-6')
					}
					aria-hidden='true'
				>
					{icon}
				</span>

				{name}
			</Link>
		</div>
	);
}

function NavItemWithChildren({ name, icon, children }) {
	function classNames(...classes) {
		return classes.filter(Boolean).join(' ');
	}

	let current = false;

	if (
		window.location.pathname === '/facility-inventory' ||
		window.location.pathname === '/item-inventory'
	)
		current = true;

	return (
		<Disclosure as='div' className='space-y-1'>
			{({ open }) => (
				<>
					<Disclosure.Button
						className={classNames(
							current
								? 'bg-shadow text-gray-900'
								: ' text-gray-800 hover:bg-shadow hover:text-gray-900',
							'group w-full flex items-center pl-2 pr-1 py-2 text-left text-sm font-medium rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500'
						)}
					>
						{icon}
						<span className='flex-1'>{name}</span>
						<svg
							className={classNames(
								open ? 'text-gray-800 rotate-90' : 'text-gray-700',
								'ml-3 flex-shrink-0 h-5 w-5  group-hover:text-gray-400 transition-colors ease-in-out duration-150'
							)}
							viewBox='0 0 20 20'
							aria-hidden='true'
						>
							<path d='M6 6L14 10L6 14V6Z' fill='currentColor' />
						</svg>
					</Disclosure.Button>

					<Transition
						show={open}
						enter='transition duration-300 ease-in'
						enterFrom='transform scale-95 opacity-0'
						enterTo='transform scale-100 opacity-100'
						leave='transition duration-150 ease-in'
						leaveFrom='transform scale-100 opacity-100'
						leaveTo='transform scale-95 opacity-0'
					>
						{children.map((child, idx) => (
							<Disclosure.Panel key={`nav-item-child-${idx}`} static>
								{child}
							</Disclosure.Panel>
						))}
					</Transition>
				</>
			)}
		</Disclosure>
	);
}

function NavItemChild({ path, handleClick, name }) {
	function classNames(...classes) {
		return classes.filter(Boolean).join(' ');
	}

	let current = false;

	return (
		<Disclosure.Panel className='space-y-1'>
			<Disclosure.Button
				as='button'
				className='group w-full flex items-center pl-11 pr-2 py-2 text-sm font-medium text-gray-800 rounded-md hover:text-gray-900 hover:bg-shadow'
			>
				<Link
					to={path}
					className={classNames(
						current ? ' text-gray-900' : ' text-gray-800  hover:text-gray-900',
						'group w-full flex items-center pl-2 py-2 text-sm font-medium rounded-md'
					)}
					onClick={handleClick}
				>
					{name}
				</Link>
			</Disclosure.Button>
		</Disclosure.Panel>
	);
}

//Granting Access to Child Components of NavigationBar.
NavigationBar.displayName = 'Navigation';
NavItem.displayName = 'NavItem';
NavItemWithChildren.displayName = 'NavItemWithChildren';
NavItemChild.displayName = 'NavItemChild';

NavigationBar.NavItem = NavItem;
NavigationBar.NavItemWithChildren = NavItemWithChildren;
NavigationBar.NavItemChild = NavItemChild;
