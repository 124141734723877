import { useEffect } from 'react';
import { useSelector } from 'react-redux';

/* Intersection Observer Hook. Expects an object containing:
target to observe(a selector string. Mandatory),
 a callback that handles when the target is in the viewport(optional), 
 a callback that handles when the target is out of the viewport(optional),
 a root selector (optional).
*/

function useIntersectionObserver({
	target,
	handleInViewPort,
	handleOutOfViewPort,
	root,
}) {
	const defaultCallBack = () => null;
	const handleInViewPortCallBack = handleInViewPort || defaultCallBack;
	const handleOutOfViewPortCallBack = handleOutOfViewPort || defaultCallBack;
	const inventoryNextPage = useSelector(
		(state) => state?.inventory?.inventory?.meta?.next_page
	);
	const itemsNextPage = useSelector(
		(state) => state?.items?.items?.meta?.next_page
	);

	const options = {
		root: document.querySelector(root || null),
		threshold: 1.0,
	};

	const handleObserver = (entries) => {
		entries.forEach((entry) => {
			if (entry.intersectionRatio > 0) {
				handleInViewPortCallBack();
			} else {
				handleOutOfViewPortCallBack();
			}
		});
	};

	useEffect(() => {
		const observer = new IntersectionObserver(handleObserver, options);
		observer.observe(document.querySelector(target));
		if (inventoryNextPage === null || itemsNextPage === null) {
			observer.disconnect();
		}
		return () => observer.disconnect();
	});
}

export default useIntersectionObserver;
