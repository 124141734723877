import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import customersService from '../../services/customersService';
import { ERROR_GENERIC, NO_RESULTS, NO_MORE_RESULTS } from '../../util/strings';


export const searchFields = [
  {
    name: 'Last Name',
    type: 'text',
    id: 'last_name',
  },
  {
    name: 'Email',
    type: 'email',
    id: 'email_address',
  },
  {
    name: 'Medical ID',
    type: 'text',
    id: 'identifier',
  },
  {
    name: 'Customer ID',
    type: 'text',
    id: 'eil_id',
  },
  {
    name: 'LeafLogix ID',
    type: 'text',
    id: 'leafLogix_id',
  },

  {
    name: 'Magneto ID',
    type: 'text',
    id: 'magento_id',
  },
];

export const displayFields = [{
  name: 'First Name',
  type: 'text',
  id: 'first_name',
}, ...searchFields];

export const initialState = {
  customers: { results: [] },
  searchValues: {
    last_name: undefined,
    email_address: undefined,
    identifier: undefined,
    eil_id: undefined,
    leafLogix_id: undefined,
    magento_id: undefined,
  },
  customersLoading: false,
  customerMsg: '',
};

export const fetchCustomers = createAsyncThunk(
  'customers/fetchCustomers',
  async (idToken, { getState }) => {
    const { searchValues } = getState().customers;
    return customersService.fetchCustomers(idToken, searchValues);
  }
);

export const fetchMoreCustomers = createAsyncThunk(
  'customers/fetchMoreCustomers',
  async (idToken, { getState, rejectWithValue }) => {
    const { customers } = getState().customers;
    const { searchValues } = getState().customers;

    if (!customers.meta?.next_page) {
      return rejectWithValue(NO_MORE_RESULTS);
    }

    const theURL = new URL(customers.meta.next_page);
    const nextPageNumber = theURL.searchParams.get("page");

    return customersService.fetchMoreCustomers(
      idToken,
      nextPageNumber,
      searchValues,
    );
  },
  {
    condition: (_, { getState }) => {
      const { customersLoading, customers } = getState().customers;
      if (customersLoading || !customers.results?.length) {
        return false;
      }
    },
  }
);

export const customersSlice = createSlice({
  name: 'customers',
  initialState,
  reducers: {
    setSearch: (state, action) => {
      state.searchValues = { ...state.searchValues, ...action.payload };
    },
    clearSearch: (state, action) => {
      state.searchValues = initialState.searchValues;
    },
    setCustomerMsg: (state, action) => {
      state.customerMsg = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCustomers.pending, (state, action) => {
        state.customersLoading = true;
      })
      .addCase(fetchCustomers.rejected, (state, action) => {
        state.customerMsg =
          action?.error?.message || ERROR_GENERIC;
        state.customersLoading = false;
      })
      .addCase(fetchCustomers.fulfilled, (state, action) => {
        state.customersLoading = false;
        const json = action.payload;

        if (!json?.results?.length) {
          state.customers = { results: [] };
          state.customerMsg = NO_RESULTS;
        } else {
          state.customers = {
            ...json,
          };

          if (!json?.meta?.next_page) {
            state.customerMsg = NO_MORE_RESULTS;
          }
        }
      })
      .addCase(fetchMoreCustomers.pending, (state, action) => {
        state.customersLoading = true;
      })
      .addCase(fetchMoreCustomers.rejected, (state, action) => {
        state.customerMsg =
          action?.error?.message || ERROR_GENERIC;
        state.customersLoading = false;
      })
      .addCase(fetchMoreCustomers.fulfilled, (state, action) => {
        state.customersLoading = false;
        const json = action.payload;

        if (!json?.results?.length) {
          state.customers = { results: [] };
          state.customerMsg = NO_RESULTS;
        } else {
          const newCustomers = json?.results ?? [];
          state.customers = {
            ...json,
            results: [...state.customers.results, ...newCustomers],
          };

          if (!json?.meta?.next_page) {
            state.customerMsg = NO_MORE_RESULTS;
          }
        }
      })
  },
});

export const { setSearch, clearSearch, setCustomerMsg } = customersSlice.actions;
