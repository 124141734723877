import { createSlice } from '@reduxjs/toolkit';

export const PATHS = {
	FACILITY_INVENTORY: '/facility-inventory',
	ITEM_INVENTORY: '/item-inventory',
	PAYMENTS: '/payments',
	ORDERS: '/orders',
	CUSTOMERS: '/customers',
};

const initialState = {
	selectedItem: PATHS.FACILITY_INVENTORY,
};

export const navigationBar = createSlice({
	name: 'navigationBar',
	initialState,
	reducers: {
		selectItem: (state, action) => {
			state.selectedItem = action.payload;
		},
	},
});

export const { selectItem } = navigationBar.actions;

export default navigationBar.reducer;
