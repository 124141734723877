import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import inventoryService from '../../services/inventory';
import { ERROR_GENERIC, NO_RESULTS, NO_MORE_RESULTS } from '../../util/strings';

function compareFacilityNames(a, b) {
	if (a.facility.name < b.facility.name) {
		return -1;
	}
	if (a.facility.name > b.facility.name) {
		return 1;
	}
	return 0;
}

function compareItemNames(a, b) {
	if (a.item.name < b.item.name) {
		return -1;
	}
	if (a.item.name > b.item.name) {
		return 1;
	}
	return 0;
}

export const initialState = {
	item: null,
	facility: null,
	inventory: { results: [] },
	inventoryMsg: null,
	isInventoryLoading: false,
	isMoreInventoryLoading: false,
};

export const fetchInventoryForFacility = createAsyncThunk(
	'inventory/fetchInventoryForFacility',
	async (idToken, { getState }) => {
		const { id } = getState().inventory.facility;
		return await inventoryService.fetchInventoryForFacility(idToken, id);
	}
);

export const fetchMoreInventoryForFacility = createAsyncThunk(
	'inventory/fetchMoreInventoryForFacility',
	async (idToken, { getState, rejectWithValue }) => {
		const { facility, inventory } = getState().inventory;

		if (!inventory.meta?.next_page) {
			return rejectWithValue(NO_MORE_RESULTS);
		}

		const nextPageNumber = /[0-9]+$/.exec(inventory.meta.next_page);
		return await inventoryService.fetchMoreInventoryForFacility(
			idToken,
			nextPageNumber,
			facility.id
		);
	},
	{
		condition: (_, { getState }) => {
			const { isMoreInventoryLoading, inventory } = getState().inventory;
			if (isMoreInventoryLoading || !inventory.results?.length) {
				return false;
			}
		},
	}
);

export const fetchInventoryForItem = createAsyncThunk(
	'inventory/fetchInventoryForItem',
	async (idToken, { getState }) => {
		const { id } = getState().inventory.item;
		return await inventoryService.fetchInventoryForItem(idToken, id);
	}
);

export const fetchMoreInventoryForItem = createAsyncThunk(
	'inventory/fetchMoreInventoryForItem',
	async (idToken, { getState, rejectWithValue }) => {
		const { item, inventory } = getState().inventory;

		if (!inventory.meta?.next_page) {
			return rejectWithValue(NO_MORE_RESULTS);
		}

		const nextPageNumber = /[0-9]+$/.exec(inventory.meta.next_page);
		return await inventoryService.fetchMoreInventoryForItem(
			idToken,
			nextPageNumber,
			item.id
		);
	},
	{
		condition: (_, { getState }) => {
			const { isMoreInventoryLoading, inventory } = getState().inventory;
			if (isMoreInventoryLoading || !inventory.results?.length) {
				return false;
			}
		},
	}
);

export const inventorySlice = createSlice({
	name: 'inventory',
	initialState,
	reducers: {
		setItem: (state, action) => {
			state.item = action.payload;
		},
		setFacility: (state, action) => {
			state.facility = action.payload;
		},
		setInventoryMsg: (state, action) => {
			state.inventoryMsg = action.payload;
		},
		setInitialState: (state, action) => {
			state.item = null;
			state.facility = null;
			state.inventory = { results: [] };
			state.inventoryMsg = null;
			state.isInventoryLoading = false;
			state.isMoreInventoryLoading = false;
		},
	},
	extraReducers: (builder) => {
		//You can learn about the builder function here : https://redux-toolkit.js.org/api/createSlice#the-extrareducers-builder-callback-notation
		builder
			.addCase(fetchInventoryForFacility.pending, (state) => {
				state.isInventoryLoading = true;
				state.inventoryMsg = null;
			})

			.addCase(fetchInventoryForFacility.fulfilled, (state, action) => {
				state.isInventoryLoading = false;

				const json = action.payload;

				if (!json?.results?.length) {
					state.inventoryMsg = NO_RESULTS;
				} else {
					state.inventory = {
						...json,
						results: json.results.sort(compareItemNames),
					};

					if (!json?.meta?.next_page) {
						state.inventoryMsg = NO_MORE_RESULTS;
					}
				}
			})

			.addCase(fetchInventoryForFacility.rejected, (state) => {
				state.isFacilitiesLoading = false;
				state.inventoryMsg = ERROR_GENERIC;
			})

			.addCase(fetchMoreInventoryForFacility.pending, (state) => {
				state.isMoreInventoryLoading = true;
				state.inventoryMsg = null;
			})

			.addCase(fetchMoreInventoryForFacility.fulfilled, (state, action) => {
				state.isMoreInventoryLoading = false;

				const json = action.payload;
				const newInventory = json?.results ?? [];
				const combinedInventory = [...state.inventory.results, ...newInventory];
				state.inventory = {
					...json,
					results: combinedInventory.sort(compareItemNames),
				};
			})

			.addCase(fetchMoreInventoryForFacility.rejected, (state, action) => {
				state.isMoreInventoryLoading = false;
				state.inventoryMsg = action.payload || ERROR_GENERIC;
			})

			.addCase(fetchInventoryForItem.pending, (state) => {
				state.isInventoryLoading = true;
				state.inventoryMsg = null;
			})

			.addCase(fetchInventoryForItem.fulfilled, (state, action) => {
				state.isInventoryLoading = false;
				const json = action.payload;

				if (!json?.results?.length) {
					state.inventoryMsg = NO_RESULTS;
				} else {
					state.inventory = {
						...json,
						results: json.results.sort(compareFacilityNames),
					};

					if (!json?.meta?.next_page) {
						state.inventoryMsg = NO_MORE_RESULTS;
					}
				}
			})

			.addCase(fetchInventoryForItem.rejected, (state) => {
				state.isFacilitiesLoading = false;
				state.inventoryMsg = ERROR_GENERIC;
			})

			.addCase(fetchMoreInventoryForItem.pending, (state) => {
				state.isMoreInventoryLoading = true;
				state.inventoryMsg = null;
			})

			.addCase(fetchMoreInventoryForItem.fulfilled, (state, action) => {
				state.isMoreInventoryLoading = false;
				const json = action.payload;
				const newInventory = json?.results ?? [];
				const combinedInventory = [...state.inventory.results, ...newInventory];
				state.inventory = {
					...json,
					results: combinedInventory.sort(compareFacilityNames),
				};
			})

			.addCase(fetchMoreInventoryForItem.rejected, (state, action) => {
				state.isMoreInventoryLoading = false;
				state.inventoryMsg = action.payload || ERROR_GENERIC;
			});
	},
});

export const { setItem, setFacility, setInitialState, setInventoryMsg } =
	inventorySlice.actions;
