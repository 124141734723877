import { HEADER_X_API_KEY } from '../util/strings';

const facilitiesService = {
	fetchFacilities: async (idToken) => {
		const res = await fetch(
			`${process.env.REACT_APP_API_ENDPOINT_BASE}/facilities`,
			{
				headers: {
					[HEADER_X_API_KEY]: idToken.idToken,
				},
			}
		);

		return res.json();
	},
};

export default facilitiesService;
