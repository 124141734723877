const Input = ({ type, name, id, placeholder, styles, value, onChange }) => {
  return (
    <div className='flex rounded-md shadow-sm'>
      <input
        type={type}
        name={name}
        id={id}
        className={`focus:ring-indigo-500 focus:border-indigo-500 flex-1 block w-full rounded-md sm:text-sm md:text-base border border-gray-300 shadow-sm p-1 pl-2 font-sans font-light ${styles}`}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
      />
    </div>
  );
};

export default Input;
