import TableRow, {
	SkeletonTableRow,
} from '../../../../components/TableRow/TableRow';
import SellStatusIcon from '../../../../components/SellStatusIcon/SellStatusIcon';
import ChevronRightIcon from '../../../../components/Icons/ChevronRightIcon';
import renderSkeletonListItems from '../../../../util/renderSkeletonListItems';

const InventoryListItem = ({ inventoryItem, renderModal }) => (
	<TableRow key={inventoryItem.id} handleClick={renderModal}>
		<TableRow.TableData width='w-1/8'>
			<TableRow.TableContainer styles='text-left'>
				{inventoryItem.facility.name ? inventoryItem.facility.name : 'N/A'}
			</TableRow.TableContainer>
		</TableRow.TableData>

		<TableRow.TableData width='w-1/12'>
			<TableRow.TableContainer>
				{inventoryItem.ecommerce_quantity
					? inventoryItem.ecommerce_quantity
					: 'N/A'}
			</TableRow.TableContainer>
		</TableRow.TableData>

		<TableRow.TableData width='w-1/12'>
			<TableRow.TableContainer>
				{inventoryItem.ecommerce_low_inventory_threshold
					? inventoryItem.ecommerce_low_inventory_threshold
					: 'N/A'}
			</TableRow.TableContainer>
		</TableRow.TableData>

		<TableRow.TableData width='w-2/12'>
			<TableRow.TableContainer>
				{inventoryItem.ecommerce_quantity >
				inventoryItem.ecommerce_low_inventory_threshold ? (
					<SellStatusIcon sellable styles='' />
				) : (
					<SellStatusIcon notSellable styles='' />
				)}
			</TableRow.TableContainer>
		</TableRow.TableData>

		<TableRow.TableData width='w-1/8'>
			<TableRow.TableContainer>
				{inventoryItem.item.part_number
					? inventoryItem.item.part_number
					: 'N/A'}
			</TableRow.TableContainer>
		</TableRow.TableData>

		<TableRow.TableData width='w-1/8'>
			<TableRow.TableContainer>
				{inventoryItem.item.source_systems[0].external_id
					? inventoryItem.item.source_systems[0].external_id
					: 'N/A'}
			</TableRow.TableContainer>
		</TableRow.TableData>

		<TableRow.TableData width='w-2/12'>
			<ChevronRightIcon styles='ml-auto text-gray-400 hover:text-green-400' />
		</TableRow.TableData>
	</TableRow>
);

export const InventoryListItemSkeleton = ({ idx }) => {
	return (
		<SkeletonTableRow key={idx}>{renderSkeletonListItems(7)}</SkeletonTableRow>
	);
};

export default InventoryListItem;
