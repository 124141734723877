import React from 'react';
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import { Security, SecureRoute, LoginCallback } from '@okta/okta-react';
import { Switch, Route, Redirect, useHistory } from 'react-router-dom';
import { BrowserRouter as Router } from 'react-router-dom';

import oktaConfig from './oktaConfig';
import LoginSSO from './routes/LoginSSO/LoginSSO';
import NotFound from './routes/NotFound/NotFound';
import FacilityInventoryList from './features/Inventory/Facility/FacilityInventoryList/FacilityInventoryList';
import CreatePayment from './features/Payments/CreatePayment/CreatePayment';
import ItemInventoryList from './features/Inventory/Items/ItemInventoryList/ItemInventoryList';
import PrintOrders from './features/PrintOrders';
import Layout from './components/Layout/Layout';
import ItemsList from './features/Inventory/Items/ItemsList/ItemsList';
import FacilityList from './features/Inventory/Facility/FacilityList/FacilityList';
import Customers from './features/Customers/Customers';

const oktaAuth = new OktaAuth(oktaConfig);

export const App = () => {
	const history = useHistory();
	const restoreOriginalUri = async (_oktaAuth, originalUri) => {
		history.replace(
			toRelativeUrl(toRelativeUrl(originalUri || '/', window.location.origin))
		);
	};

	return (
		<Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
			<Layout>
				<Switch>
					<Redirect exact from='/' to='/facility-inventory' />
					<Route exact path='/login/sso' component={LoginSSO} />
					<Route exact path='/login/callback' component={LoginCallback} />
					<SecureRoute exact path='/payments' component={CreatePayment} />
					<SecureRoute
						exact
						path='/facility-inventory'
						component={FacilityList}
					/>
					<SecureRoute exact path='/item-inventory' component={ItemsList} />
					<SecureRoute
						exact
						path='/item-inventory/item/*'
						component={ItemInventoryList}
					/>
					<SecureRoute
						exact
						path='/facility-inventory/facility/*'
						component={FacilityInventoryList}
					/>
					<SecureRoute exact path='/orders' component={PrintOrders} />
					<SecureRoute exact path='/customers' component={Customers} />
					<Route component={NotFound} />
				</Switch>
			</Layout>
		</Security>
	);
};

const AppWithRouterAccess = () => {
	return (
		<Router>
			<App />
		</Router>
	);
};

export default AppWithRouterAccess;
