import { Fragment, useRef } from 'react';
import { Dialog, Transition } from '@headlessui/react';

import CloseIcon from '../../../../components/Icons/CloseIcon';
import Card from '../../../../components/Card/Card';
import SellStatusIcon from '../../../../components/SellStatusIcon/SellStatusIcon';

const InventoryItemModal = ({
	showModal,
	setShowModal,
	setModalItem,
	item,
}) => {
	const cancelButtonRef = useRef(null);

	return (
		<Transition.Root show={showModal} as={Fragment}>
			<Dialog
				as='div'
				static
				className='fixed z-10 inset-1 md:top-20 lg:top-1 lg:left-60 overflow-y-auto'
				initialFocus={cancelButtonRef}
				open={showModal}
				onClose={() => {
					setShowModal(false);
					setModalItem(null);
				}}
			>
				{/* Overlay Container */}
				<div className='flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0'>
					<Transition.Child
						as={Fragment}
						enter='ease-out duration-300'
						enterFrom='opacity-0'
						enterTo='opacity-100'
						leave='ease-in duration-300'
						leaveFrom='opacity-100'
						leaveTo='opacity-0'
					>
						<Dialog.Overlay className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity' />
					</Transition.Child>

					{/* This element is to trick the browser into centering the modal contents. */}
					<span
						className='hidden sm:inline-block sm:align-middle sm:h-screen'
						aria-hidden='true'
					>
						&#8203;
					</span>
					<Transition.Child
						as={Fragment}
						enter='ease-out duration-300'
						enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
						enterTo='opacity-100 translate-y-0 sm:scale-100'
						leave='ease-in duration-200'
						leaveFrom='opacity-100 translate-y-0 sm:scale-100'
						leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
					>
						{/* Modal Container */}
						<div className='inline-block align-bottom rounded-lg text-left shadow-xl transform transition-all sm:align-middle max-w-full md:w-11/12 md:max-w-2xl lg:max-w-6xl my-20 md:mt-0 mb-auto max-w'>
							<div className='bg-white px-4 py-3 sm:px-6'>
								<div className='flex text-base border-b-2 border-gray-200 text-center mx-auto'>
									<h2 className='text-center font-medium text-gray-500 uppercase tracking-wider py-1 md:text-2xl'>
										Item Details -{' '}
										<span className='uppercase'>{item.item.name}</span>
									</h2>
									<div
										className='ml-auto'
										onClick={() => {
											setShowModal(false);
											setModalItem(null);
										}}
										ref={cancelButtonRef}
									>
										<CloseIcon styles='text-gray-700 hover:text-green-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500' />
									</div>
								</div>
							</div>
							<div className='bg-white overflow-auto'>
								<div className='grid md:grid-rows-2 md:grid-cols-2 gap-6 px-3 pb-3'>
									{/* Card 1 */}
									<Card title='Item Information'>
										<table className='min-w-full divide-y divide-gray-200'>
											<tbody className='text-sm divide-y divide-gray-200 text-left'>
												<tr className=''>
													<td className='py-2 capitalize font-bold pl-5'>
														ItemID
													</td>
													<td className='py-2 capitalize text-gray-600'>
														{item.item.id ? item.item.id : 'N/A'}
													</td>
												</tr>
												<tr>
													<td className='py-2 capitalize font-bold pl-5'>
														Part #
													</td>
													<td className='py-2 capitalize text-gray-600'>
														{item.item.part_number
															? item.item.part_number
															: 'N/A'}
													</td>
												</tr>
												<tr>
													<td className='py-2 capitalize font-bold pl-5'>
														Flourish ID
													</td>
													<td className='py-2 capitalize text-gray-600'>
														{item.item?.source_systems[0]?.external_id
															? item.item?.source_systems[0]?.external_id
															: 'N / A'}
													</td>
												</tr>

												<tr className=''>
													<td className='py-2 capitalize font-bold pl-5'>
														Retail Name
													</td>
													<td className='py-2 capitalize text-gray-600'>
														{item.item.retail_name
															? item.item.retail_name
															: 'N/A'}
													</td>
												</tr>
												<tr>
													<td className='py-2 capitalize font-bold pl-5'>
														Brand
													</td>
													<td className='py-2 capitalize text-gray-600'>
														{item.item.brand ? item.item.brand : 'N/A'}
													</td>
												</tr>
											</tbody>
										</table>
									</Card>
									{/* Card 2 */}
									<Card title='Item Location Information'>
										<table className='min-w-full divide-y divide-gray-200'>
											<tbody className='text-sm divide-y divide-gray-200 text-left'>
												<tr className=''>
													<td className='py-2 capitalize font-bold pl-5'>
														Facility Name
													</td>
													<td className='py-2 capitalize text-gray-600'>
														{item.facility.name ? item.facility.name : 'N/A'}
													</td>
												</tr>
												<tr>
													<td className='py-2 capitalize font-bold pl-5'>
														Facility Type
													</td>
													<td className='py-2 capitalize text-gray-600'>
														{item.facility.facility_type
															? item.facility.facility_type
															: 'N/A'}
													</td>
												</tr>
												<tr className=''>
													<td className='py-2 capitalize font-bold pl-5'>
														Location
													</td>
													<td className='py-2 capitalize text-gray-600'>
														{item.facility.city && item.facility.state
															? item.facility.city + ', ' + item.facility.state
															: 'N/A'}
													</td>
												</tr>
												<tr>
													<td className='py-2 capitalize font-bold pl-5'>
														Email
													</td>
													<td className='py-2 capitalize text-gray-600'>
														{item.facility.email ? item.facility.email : 'N/A'}
													</td>
												</tr>
												<tr className=''>
													<td className='py-2 capitalize font-bold pl-5'>
														Phone
													</td>
													<td className='py-2 capitalize text-gray-600'>
														{item.facility.phone ? item.facility.phone : 'N/A'}
													</td>
												</tr>
												<tr>
													<td className='py-2 capitalize font-bold pl-5'>
														Address
													</td>
													<td className='py-2 capitalize text-gray-600'>
														{item.facility.street_address_1 &&
														item.facility.street_address_2
															? item.facility.street_address_1 +
															  ', ' +
															  item.facility.street_address_2
															: item.facility.street_address_1
															? item.facility.street_address_1
															: 'N/A'}
													</td>
												</tr>
												<tr className=''>
													<td className='py-2 capitalize font-bold pl-5'>
														Facility ID
													</td>
													<td className='py-2 text-gray-600'>
														{item.facility.id ? item.facility.id : 'N/A'}
													</td>
												</tr>
											</tbody>
										</table>
									</Card>

									<Card title='THC & CBD Information'>
										<div className='grid grid-cols-2 grid-rows-2 p-3 gap-5 text-center'>
											<Card title='Min THC'>
												<div className='py-5'>
													<h6>{item?.min_thc}%</h6>
												</div>
											</Card>
											<Card title='Max THC'>
												<div className='py-5'>
													<h6>{item?.max_thc}%</h6>
												</div>
											</Card>
											<Card title='Min CBD'>
												<div className='py-5'>
													<h6>{item?.min_cbd}%</h6>
												</div>
											</Card>
											<Card title='Max CBD'>
												<div className='py-5'>
													<h6>{item?.max_cbd}%</h6>
												</div>
											</Card>
										</div>
									</Card>

									<Card title='Stock Information'>
										<div className='grid grid-cols-2 grid-rows-2 p-3 gap-5 text-center'>
											<Card title='Status' styles='col-span-full'>
												<div className='py-5'>
													{item.ecommerce_quantity >
													item.ecommerce_low_inventory_threshold ? (
														<SellStatusIcon
															sellable
															styles='text-3xl px-4 py-2'
														/>
													) : (
														<SellStatusIcon
															notSellable
															styles='text-3xl px-4 py-2'
														/>
													)}
												</div>
											</Card>
											<Card title='Quantity'>
												<div className='py-5'>
													<h6>{item?.ecommerce_quantity}</h6>
												</div>
											</Card>
											<Card title='Threshold'>
												<div className='py-5'>
													<h6>{item?.ecommerce_low_inventory_threshold}</h6>
												</div>
											</Card>
										</div>
									</Card>
								</div>
							</div>
						</div>
					</Transition.Child>
				</div>
			</Dialog>
		</Transition.Root>
	);
};

export default InventoryItemModal;
