import TableRow, {
  SkeletonTableRow,
} from '../../components/TableRow/TableRow';
import renderSkeletonListItems from '../../util/renderSkeletonListItems';

function getSourceSystemID(customer, source_system_name) {
  let sourceSystemID;
  if (customer &&
    customer.source_ids &&
    customer.source_ids.length > 0) {
    customer.source_ids.map((css) => {
      if (css &&
        css.source_system &&
        css.source_system.name &&
        css.source_system.name === source_system_name) {
        sourceSystemID = css.external_id;
      }
      return true;
    });
  }
  return sourceSystemID;
}

function getMedicalID(customer) {
  let medicalID;
  if (customer &&
    customer.identity_documents &&
    customer.identity_documents.length > 0) {
    customer.identity_documents.map((cid) => {
      if (cid &&
        cid.Type &&
        cid.Type === 1) {
        medicalID = cid.identifier;
      }
    });
  }
  return medicalID;
}

const CustomerList = ({ data, renderModal }) => (
  <TableRow reactKey={data?.id} handleClick={renderModal} >
    <TableRow.TableData width={'w-1/12'}>
      <TableRow.TableContainer>
        {data?.first_name}
      </TableRow.TableContainer>
    </TableRow.TableData>

    <TableRow.TableData width={'w-1/12'}>
      <TableRow.TableContainer>
        {data?.last_name}
      </TableRow.TableContainer>
    </TableRow.TableData>

    <TableRow.TableData width={'w-1/12'}>
      <TableRow.TableContainer>
        {data?.email}
      </TableRow.TableContainer>
    </TableRow.TableData>

    {/* Medical ID */}
    <TableRow.TableData width={'w-1/12'}>
      <TableRow.TableContainer>
        {getMedicalID(data)}
      </TableRow.TableContainer>
    </TableRow.TableData>

    {/* //Customer ID */}
    <TableRow.TableData width={'w-1/12'}>
      <TableRow.TableContainer>
        {data?.id}
      </TableRow.TableContainer>
    </TableRow.TableData>

    {/* Leaf Logix ID */}
    <TableRow.TableData width={'w-1/12'}>
      <TableRow.TableContainer>
        {getSourceSystemID(data, 'Leaflogix')}
      </TableRow.TableContainer>
    </TableRow.TableData>

    {/* Magneto ID */}
    <TableRow.TableData width={'w-1/12'}>
      <TableRow.TableContainer>
        {getSourceSystemID(data, 'Magento')}
      </TableRow.TableContainer>
    </TableRow.TableData>
  </TableRow >
);

export const InventoryListItemSkeleton = ({ idx }) => {
  return (
    <SkeletonTableRow key={idx}>{renderSkeletonListItems(7)}</SkeletonTableRow>
  );
};

export default CustomerList;
