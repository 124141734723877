import React, { useEffect, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useOktaAuth } from '@okta/okta-react';
import { Transition } from '@headlessui/react';

import { fetchFacilities } from '../../Inventory/Facility/facilitiesState';
import {
	COMMON_BRAND_OPTIONS,
	COMMON_ORDER_TYPE_OPTIONS,
	PATTERN_COMMON_DATE,
} from '../../../util/constants';
import * as paymentActions from '../paymentsState';

import PaymentsModal from '../PaymentsModal/PaymentsModal';
import Alert from '../../../components/Alert/Alert';
import Label from '../../../components/Label';
import Button from '../../../components/Button';
import { EMAIL_ADDRESS, TEN_DIGIT_NUMBER } from '../../../util/formValidation';
import renderOptions from './renderOptions';
import SearchBar from '../../SearchBar/SearchBar';
import { selectPaymentsView, setSearch } from '../../SearchBar/searchBarState';

const CreatePayment = () => {
	const dispatch = useDispatch();
	const { authState } = useOktaAuth();

	const facilities = useSelector(({ facilities }) => facilities.facilities);
	// const isFacilitiesLoading = useSelector(
	// 	({ facilities }) => facilities.isFacilitiesLoading
	// );

	const amountForPost = useSelector(({ payments }) => payments.amountForPost);
	const facilityForPost = useSelector(
		({ payments }) => payments.facilityForPost
	);
	const lastNameForPost = useSelector(
		({ payments }) => payments.lastNameForPost
	);
	const orderDateForPost = useSelector(
		({ payments }) => payments.orderDateForPost
	);
	const orderTypeForPost = useSelector(
		({ payments }) => payments.orderTypeForPost
	);
	const firstNameForPost = useSelector(
		({ payments }) => payments.firstNameForPost
	);
	const orderNumberForPost = useSelector(
		({ payments }) => payments.orderNumberForPost
	);
	const retailBrandForPost = useSelector(
		({ payments }) => payments.retailBrandForPost
	);
	const phoneNumberForPost = useSelector(
		({ payments }) => payments.phoneNumberForPost
	);
	const emailAddressForPost = useSelector(
		({ payments }) => payments.emailAddressForPost
	);

	const createPayReqMsg = useSelector(
		({ payments }) => payments.createPayReqMsg
	);
	const isCreatePayReqLoading = useSelector(
		({ payments }) => payments.isCreatePayReqLoading
	);

	const paymentSuccess = useSelector(({ payments }) => payments.paymentSuccess);

	const showPaymentModal = useSelector(
		({ payments }) => payments.showPaymentModal
	);

	const payReqArr = useSelector(({ payments }) => payments.payReqArr);
	const submitPayReqError = useSelector(
		({ payments }) => payments.submitPayReqError
	);

	const handlePaymentsSearch = (e) => {
		const searchQuery = e.target.value;
		dispatch(paymentActions.setPayReqOrderNum(searchQuery));
	};

	const handlePaymentsSubmit = (e) => {
		const searchQuery = e.target.value;

		if (e.key === 'Enter') {
			dispatch(
				paymentActions.getPayReqStatus({
					searchQuery,
					idToken: authState?.idToken,
				})
			);
		}
	};

	useEffect(() => {
		dispatch(selectPaymentsView());
		dispatch(setSearch(''));
		dispatch(fetchFacilities(authState?.idToken)).then(({ payload }) => {
			dispatch(paymentActions.populateRecentInputs(payload));
		});
	}, [dispatch, authState?.idToken]);

	const facilityOptions = facilities?.results?.map((facility) => ({
		key: facility.id,
		value: facility.id,
		text: facility.name,
	}));

	return (
		<>
			<div className='mx-4'>
				<SearchBar
					handleSearch={handlePaymentsSearch}
					handleSubmit={handlePaymentsSubmit}
					placeholder='Enter an Order# to look up a payment...'
					queryParamOptions={[
						{
							key: 'order_number',
							text: 'Order Number',
							value: 'order_number',
						},
					]}
				/>
			</div>
			<div className='mt-1 mx-4'>
				{payReqArr && (
					<PaymentsModal
						payReqArr={payReqArr}
						showModal={true}
						handleClose={() => dispatch(paymentActions.setPayReqArr())}
					/>
				)}

				<Transition
					as={Fragment}
					show={showPaymentModal}
					enter='transform transition duration-[400ms]'
					enterFrom='opacity-0 scale-50'
					enterTo='opacity-100 scale-100'
					leave='transform duration-200 transition ease-in-out'
					leaveFrom='opacity-100 scale-100 '
					leaveTo='opacity-0 scale-95 '
				>
					<div>
						<PaymentsModal
							payReqArr={payReqArr}
							showModal={showPaymentModal}
							handleClose={() => {
								dispatch(paymentActions.setShowPaymentModal(false));
								dispatch(paymentActions.setPayReqArr());
							}}
						/>
					</div>
				</Transition>

				<div className='mb-4'>
					{/* Create a payment success message */}
					<Transition
						as={Fragment}
						show={paymentSuccess}
						enter='transform transition duration-[400ms]'
						enterFrom='opacity-0  scale-50'
						enterTo='opacity-100  scale-100'
						leave='transform duration-200 transition ease-in-out'
						leaveFrom='opacity-100  scale-100 '
						leaveTo='opacity-0 scale-95 '
					>
						<div>
							<Alert
								setAlertState={() => {
									dispatch(paymentActions.setPaymentSuccess(false));
								}}
								type='success'
							>
								{createPayReqMsg}
							</Alert>
						</div>
					</Transition>

					{/* Create a payment error message */}
					<Transition
						as={Fragment}
						show={submitPayReqError}
						enter='transform transition duration-[400ms]'
						enterFrom='opacity-0  scale-50'
						enterTo='opacity-100  scale-100'
						leave='transform duration-200 transition ease-in-out'
						leaveFrom='opacity-100  scale-100 '
						leaveTo='opacity-0 scale-95 '
					>
						<div>
							<Alert
								setAlertState={() => {
									dispatch(paymentActions.setSubmitPayReqError(false));
								}}
							>
								{createPayReqMsg}
							</Alert>
						</div>
					</Transition>
				</div>

				<div className='shadow sm:rounded-md sm:overflow-hidden'>
					<form
						className=''
						onSubmit={(event) => {
							event.preventDefault();
							dispatch(paymentActions.setSubmitPayReqError(false));
							dispatch(paymentActions.setPaymentSuccess(false));
							dispatch(paymentActions.submitNewPayReq(authState?.idToken));
							return false;
						}}
					>
						<div className='px-4 py-5 bg-white space-y-6 sm:p-6'>
							<div className='sm:px-0 font-sans'>
								<h3 className='md:text-xl font-medium leading-6 text-gray-900'>
									Create Payments
								</h3>
								<p className='mt-1 text-sm text-gray-500 md:w-6/12'>
									Fill out the form below to create a payment.{' '}
									<span className=''>All fields must be filled out.</span> To
									lookup a payment, enter the Order # in the search bar above.
								</p>
							</div>

							<div className='grid md:grid-cols-2 gap-4 gap-y-6'>
								<div className='flex md:w-10/12 flex-col'>
									<Label htmlFor='retail_brand' styles='text-base mb-1'>
										Retail Brand
									</Label>
									<select
										onChange={(e) =>
											dispatch(
												paymentActions.setRetailBrandForPost(e.target.value)
											)
										}
										required='required'
										className='focus:ring-indigo-500 focus:border-indigo-500 flex-1 block w-full rounded-md sm:text-sm md:text-base border border-gray-300 shadow-sm p-1 pl-2 font-sans font-light'
										value={retailBrandForPost}
									>
										<option disabled>Select Retail Brand</option>
										{renderOptions(COMMON_BRAND_OPTIONS)}
									</select>
								</div>
								<div className='flex md:w-10/12 flex-col'>
									<Label htmlFor='facility_name' styles='text-base mb-1'>
										Facility Name
									</Label>
									<select
										onChange={(e) =>
											dispatch(
												paymentActions.setFacilityForPost(e.target.value)
											)
										}
										placeholder='Facility Name'
										required='required'
										className='focus:ring-indigo-500 focus:border-indigo-500 flex-1 block w-full rounded-md sm:text-sm md:text-base border border-gray-300 shadow-sm p-1 pl-2 font-sans font-light'
										value={facilityForPost}
									>
										<option disabled>Select Facility</option>
										{renderOptions(facilityOptions)}
									</select>
								</div>
								<div className='flex md:w-10/12 flex-col'>
									<Label htmlFor='order_number' styles='text-base mb-1'>
										Order Number
									</Label>
									<input
										name='order_number'
										onChange={(e) =>
											dispatch(
												paymentActions.setOrderNumberForPost(e.target.value)
											)
										}
										placeholder='Order ID'
										required='required'
										type='text'
										value={orderNumberForPost}
										className='focus:ring-indigo-500 focus:border-indigo-500 flex-1 block w-full rounded-md sm:text-sm md:text-base border border-gray-300 shadow-sm p-1 pl-2 font-sans font-light'
									/>
								</div>
								<div className='flex md:w-10/12 flex-col'>
									<Label htmlFor='order_type' styles='text-base mb-1'>
										Order Type
									</Label>

									<select
										onChange={(e) =>
											dispatch(
												paymentActions.setOrderTypeForPost(e.target.value)
											)
										}
										placeholder='Order Type'
										required='required'
										value={orderTypeForPost}
										className='focus:ring-indigo-500 focus:border-indigo-500 flex-1 block w-full rounded-md sm:text-sm md:text-base border border-gray-300 shadow-sm p-1 pl-2 font-sans font-light'
									>
										<option disabled>Select Order Type</option>
										{renderOptions(COMMON_ORDER_TYPE_OPTIONS)}
									</select>
								</div>
								<div className='flex md:w-10/12 flex-col'>
									<Label htmlFor='order-date' styles='text-base mb-1'>
										Order Date
									</Label>
									<input
										onChange={(e) =>
											dispatch(
												paymentActions.setOrderDateForPost(e.target.value)
											)
										}
										pattern={PATTERN_COMMON_DATE}
										placeholder='Order Date (yyyy-mm-dd)'
										required='required'
										type='date'
										value={orderDateForPost}
										className='focus:ring-indigo-500 focus:border-indigo-500 flex-1 block w-full rounded-md sm:text-sm md:text-base border border-gray-300 shadow-sm p-1 pl-2 font-sans font-light'
									/>
								</div>
								<div className='flex md:w-10/12 flex-col'>
									<Label htmlFor='email_address' styles='text-base mb-1'>
										Email Address
									</Label>
									<input
										name='email_address'
										onChange={(e) => {
											dispatch(
												paymentActions.setEmailAddressForPost(e.target.value)
											);
										}}
										placeholder='johndoe@example.com'
										type='text'
										pattern={EMAIL_ADDRESS}
										value={emailAddressForPost}
										required='required'
										className='focus:ring-indigo-500 focus:border-indigo-500 flex-1 block w-full rounded-md sm:text-sm md:text-base border border-gray-300 shadow-sm p-1 pl-2 font-sans font-light'
									/>
								</div>

								<div className='flex md:w-10/12 flex-col'>
									<Label htmlFor='first_name' styles='text-base mb-1'>
										First Name
									</Label>
									<input
										name='first_name'
										onChange={(e) =>
											dispatch(
												paymentActions.setFirstNameForPost(e.target.value)
											)
										}
										placeholder='John'
										required='required'
										type='text'
										value={firstNameForPost}
										className='focus:ring-indigo-500 focus:border-indigo-500 flex-1 block w-full rounded-md sm:text-sm md:text-base border border-gray-300 shadow-sm p-1 pl-2 font-sans font-light'
									/>
								</div>
								<div className='flex md:w-10/12 flex-col'>
									<Label htmlFor='last_name' styles='text-base mb-1'>
										Last Name
									</Label>
									<input
										name='last_name'
										onChange={(e) =>
											dispatch(
												paymentActions.setLastNameForPost(e.target.value)
											)
										}
										placeholder='Doe'
										required='required'
										type='text'
										value={lastNameForPost}
										className='focus:ring-indigo-500 focus:border-indigo-500 flex-1 block w-full rounded-md sm:text-sm md:text-base border border-gray-300 shadow-sm p-1 pl-2 font-sans font-light'
									/>
								</div>
								<div className='flex md:w-10/12 flex-col'>
									<Label htmlFor='phone_number' styles='text-base mb-1'>
										Phone Number
									</Label>
									<input
										name='phone_number'
										onChange={(e) =>
											dispatch(
												paymentActions.setPhoneNumberForPost(e.target.value)
											)
										}
										placeholder='10 Digit Phone Number'
										type='text'
										value={phoneNumberForPost}
										required='required'
										className='focus:ring-indigo-500 focus:border-indigo-500 flex-1 block w-full rounded-md sm:text-sm md:text-base border border-gray-300 shadow-sm p-1 pl-2 font-sans font-light'
										pattern={TEN_DIGIT_NUMBER}
									/>
								</div>
								<div className='flex md:w-10/12 flex-col'>
									<Label htmlFor='order_amount' styles='text-base mb-1'>
										Order Amount
									</Label>

									<input
										min='0'
										onChange={(e) =>
											dispatch(paymentActions.setAmountForPost(e.target.value))
										}
										placeholder='$0.00'
										required='required'
										step='0.01'
										type='number'
										value={amountForPost}
										className='focus:ring-indigo-500 focus:border-indigo-500 flex-1 block w-full rounded-md sm:text-sm md:text-base border border-gray-300 shadow-sm p-1 pl-2 font-sans font-light'
									/>
								</div>
							</div>
						</div>
						<div className='px-4 py-3 bg-gray-50 text-left sm:px-6'>
							<Button
								styles='text-center mx-auto'
								type='submit'
								loading={isCreatePayReqLoading}
							>
								Create Payment
							</Button>
						</div>
					</form>
				</div>
			</div>
		</>
	);
};

export default CreatePayment;
