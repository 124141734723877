import { SkeletonTableRow } from '../components/TableRow/TableRow';

const renderSkeletonListItems = (x) => {
	let skeletonItems = [];
	for (let i = 0; i < x; i++) {
		skeletonItems.push(
			<SkeletonTableRow.SkeletonTableData key={i}>
				<SkeletonTableRow.SkeletonTableContainer />
			</SkeletonTableRow.SkeletonTableData>
		);
	}
	return skeletonItems;
};

export default renderSkeletonListItems;
