import { useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useOktaAuth } from '@okta/okta-react';

import {
	fetchInventoryForItem,
	fetchMoreInventoryForItem,
	setItem,
	setInitialState,
} from '../../inventoryState';
import {
	views,
	selectItemInventoryView,
} from '../../../SearchBar/searchBarState';
import useIntersectionObserver from '../../../../util/intersectionObserver';
import InventoryListItem, {
	InventoryListItemSkeleton,
} from '../InventoryListItem/InventoryListItem';
import LeftArrowIcon from '../../../../components/Icons/LeftArrowIcon';
import InventoryItemModal from '../../Facility/InventoryItemModal/Modal';

const ItemInventoryList = () => {
	const history = useHistory();
	const dispatch = useDispatch();
	const { authState } = useOktaAuth();
	const currentView = useSelector((state) => state.searchBar.view);
	const item = history?.location?.state?.item;
	const inventory = useSelector((state) => state.inventory.inventory);
	const inventoryMsg = useSelector((state) => state.inventory.inventoryMsg);
	const isInventoryLoading = useSelector(
		(state) => state.inventory.isInventoryLoading
	);
	const isMoreInventoryLoading = useSelector(
		(state) => state.inventory.isMoreInventoryLoading
	);
	const [modalItem, setModalItem] = useState(null);
	const [showModal, setShowModal] = useState(false);

	const renderModal = (inventoryItem) => {
		setShowModal(true);
		setModalItem(inventoryItem);
	};

	const inventoryItems = inventory?.results?.map((inventoryItem, idx) => (
		<InventoryListItem
			inventoryItem={inventoryItem}
			renderModal={() => renderModal(inventoryItem)}
			key={idx}
		/>
	));

	const renderSkeletonItems = (x) => {
		let skeletonItems = [];

		for (let i = 0; i < x; i++) {
			skeletonItems.push(<InventoryListItemSkeleton key={i} />);
		}
		return skeletonItems;
	};

	useEffect(() => {
		if (currentView !== views.ITEM_INVENTORY) {
			dispatch(selectItemInventoryView());
		}
		dispatch(setItem(item));
		dispatch(fetchInventoryForItem(authState?.idToken));
		// return () => dispatch(setInitialState());
	}, [dispatch, authState?.idToken, item, currentView]);

	useIntersectionObserver({
		target: '.endOfItemsList',
		handleInViewPort: () =>
			dispatch(fetchMoreInventoryForItem(authState?.idToken)),
	});

	if (item === undefined) {
		return <Redirect to='/' />;
	}

	return (
		<div className=''>
			{showModal && (
				<InventoryItemModal
					item={modalItem}
					showModal={showModal}
					setShowModal={setShowModal}
					setModalItem={setModalItem}
				/>
			)}
			<div className='flex'>
				<button
					onClick={() => history.goBack()}
					className='mr-4 text-gray-400 hover:text-green-400'
				>
					<LeftArrowIcon />
				</button>
				<h1 className='text-2xl font-sans text-gray-600'>{`${item.name} Inventory`}</h1>
			</div>
			<div className=' mt-5 flex flex-col'>
				<div className='-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8'>
					<div className='py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8'>
						<div className='shadow overflow-hidden border-b border-gray-200'>
							<table className='min-w-full divide-y divide-gray-200'>
								<thead className='bg-gray-50 font-sans'>
									<tr>
										<th
											scope='col'
											className='pl-6 py-3 text-left text-xs font-light text-gray-500 uppercase tracking-wider'
										>
											Product Name
										</th>
										<th
											scope='col'
											className='py-3 text-xs font-light text-gray-500 uppercase tracking-wider text-center'
										>
											Quantity
										</th>
										<th
											scope='col'
											className='py-3 text-xs font-light text-gray-500 uppercase tracking-wider'
										>
											Threshold
										</th>
										<th
											scope='col'
											className='py-3 text-xs font-light text-gray-500 uppercase tracking-wider'
										>
											Status
										</th>
										<th
											scope='col'
											className='py-3 text-xs font-light text-gray-500 uppercase tracking-wider'
										>
											Part #
										</th>
										<th
											scope='col'
											className='py-3 text-xs font-light text-gray-500 uppercase tracking-wider'
										>
											Flourish ID
										</th>
										<th
											scope='col'
											className='pr-6 py-3 text-xs font-light text-gray-500 uppercase tracking-wider text-right'
										>
											Item Details
										</th>
									</tr>
								</thead>
								<tbody className='bg-white divide-y divide-gray-200'>
									{isInventoryLoading
										? renderSkeletonItems(30)
										: inventoryItems}
									{isMoreInventoryLoading ? renderSkeletonItems(3) : null}
								</tbody>
							</table>
						</div>
						<div className='mt-5 text-center'>
							<div className='endOfItemsList mt-5 text-center text-gray-500 uppercase'>
								<div>{inventoryMsg}...</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ItemInventoryList;
