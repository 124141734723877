const Label = ({ children, htmlFor, styles }) => {
	return (
		<label
			htmlFor={htmlFor}
			className={`block font-medium text-gray-700 ${styles}`}
		>
			{children}
		</label>
	);
};

export default Label;
