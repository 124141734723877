const debouncePromise = (delayMs, func) => {
  let timeout = null;

  return (...args) => {
    clearTimeout(timeout);

    return new Promise((resolve) => {
      timeout = setTimeout(() => resolve(func(...args)), delayMs);
    });
  };
};

export default debouncePromise;
