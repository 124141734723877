import TableRow, {
	SkeletonTableRow,
} from '../../../../components/TableRow/TableRow';
import ChevronRightIcon from '../../../../components/Icons/ChevronRightIcon';
import SellStatusIcon from '../../../../components/SellStatusIcon/SellStatusIcon';
import renderSkeletonListItems from '../../../../util/renderSkeletonListItems';

const FacilityInventoryListItem = ({ inventoryItem, renderModal }) => {
	return (
		<TableRow key={inventoryItem.item.id} handleClick={renderModal}>
			<TableRow.TableData styles='text-left' width='w-1/8'>
				<TableRow.TableContainer styles='text-left'>
					{inventoryItem.item.name}
				</TableRow.TableContainer>
			</TableRow.TableData>

			<TableRow.TableData width='w-1/12'>
				<TableRow.TableContainer>
					{inventoryItem.item.brand ? inventoryItem.item.brand : 'N/A'}
				</TableRow.TableContainer>
			</TableRow.TableData>

			<TableRow.TableData width='w-1/8'>
				<TableRow.TableContainer>
					{inventoryItem.ecommerce_quantity}
				</TableRow.TableContainer>
			</TableRow.TableData>

			<TableRow.TableData width='w-1/8'>
				<TableRow.TableContainer>
					{inventoryItem.ecommerce_low_inventory_threshold}
				</TableRow.TableContainer>
			</TableRow.TableData>

			<TableRow.TableData width='w-1/8'>
				<TableRow.TableContainer>
					{inventoryItem.ecommerce_quantity >
					inventoryItem.ecommerce_low_inventory_threshold ? (
						<SellStatusIcon sellable />
					) : (
						<SellStatusIcon notSellable />
					)}
				</TableRow.TableContainer>
			</TableRow.TableData>

			<TableRow.TableData width='w-2/8'>
				<TableRow.TableContainer>
					{inventoryItem.item.part_number}
				</TableRow.TableContainer>
			</TableRow.TableData>

			<TableRow.TableData width='w-2/8'>
				<TableRow.TableContainer>
					{inventoryItem.item?.source_systems[0]?.external_id
						? inventoryItem.item?.source_systems[0]?.external_id
						: 'N / A'}
				</TableRow.TableContainer>
			</TableRow.TableData>

			<TableRow.TableData width='w-1/8'>
				<ChevronRightIcon styles='ml-auto text-gray-400 hover:text-green-400' />
			</TableRow.TableData>
		</TableRow>
	);
};

const FacilityInventoryListItemSkeleton = () => {
	return <SkeletonTableRow>{renderSkeletonListItems(8)}</SkeletonTableRow>;
};

export default FacilityInventoryListItem;

export { FacilityInventoryListItemSkeleton };
