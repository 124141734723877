import { createSlice } from '@reduxjs/toolkit';

export const views = {
	INVENTORY_BY_FACILITY: 'facility',
	INVENTORY_BY_ITEM: 'item',
	PAYMENTS: 'payments',
	ORDERS: 'orders',
	FACILITY_INVENTORY: 'facility_inventory',
	ITEM_INVENTORY: 'item_inventory',
};

export const initialState = {
	view: views.INVENTORY_BY_FACILITY,
	searchQuery: '',
	showAlert: false,
	showPatternAlert: false,
	showCharCountAlert: false,
};

export const searchbarSlice = createSlice({
	name: 'searchBar',
	initialState,
	reducers: {
		selectInventoryByFacilityView: (state) => {
			state.view = views.INVENTORY_BY_FACILITY;
		},
		selectInventoryByItemView: (state) => {
			state.view = views.INVENTORY_BY_ITEM;
		},
		selectPaymentsView: (state) => {
			state.view = views.PAYMENTS;
		},
		selectOrdersView: (state) => {
			state.view = views.ORDERS;
		},
		selectFacilityInventoryView: (state) => {
			state.view = views.FACILITY_INVENTORY;
		},
		selectItemInventoryView: (state) => {
			state.view = views.ITEM_INVENTORY;
		},
		setSearch: (state, action) => {
			state.searchQuery = action.payload;
		},
		setShowAlert: (state, action) => {
			state.showAlert = action.payload;
		},
		setShowPatternAlert: (state, action) => {
			state.showPatternAlert = action.payload;
		},
		setShowCharCountAlert: (state, action) => {
			state.showCharCountAlert = action.payload;
		},
		clearAlerts: (state) => {
			state.showAlert = false;
			state.showCharCountAlert = false;
			state.showPatternAlert = false;
		},
	},
});

export const {
	selectInventoryByFacilityView,
	selectInventoryByItemView,
	selectPaymentsView,
	selectOrdersView,
	selectFacilityInventoryView,
	selectItemInventoryView,
	setSearch,
	setShowAlert,
	setShowPatternAlert,
	setShowCharCountAlert,
	clearAlerts,
} = searchbarSlice.actions;
