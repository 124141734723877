import { Fragment, useRef } from 'react';
import { Dialog, Transition } from '@headlessui/react';

import CloseIcon from '../Icons/CloseIcon';

const Modal = ({ showModal, handleClose, title, children }) => {
	const cancelButtonRef = useRef(null);

	return (
		<Transition.Root show={showModal} as={Fragment}>
			<Dialog
				as='div'
				static
				className='fixed z-10 inset-1 md:top-20 lg:top-1 lg:left-60 overflow-y-auto'
				initialFocus={cancelButtonRef}
				open={showModal}
				onClose={handleClose}
			>
				{/* Overlay Container */}
				<div className='flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0'>
					<Transition.Child
						as={Fragment}
						enter='ease-out duration-300'
						enterFrom='opacity-0'
						enterTo='opacity-100'
						leave='ease-in duration-300'
						leaveFrom='opacity-100'
						leaveTo='opacity-0'
					>
						<Dialog.Overlay className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity' />
					</Transition.Child>

					{/* This element is to trick the browser into centering the modal contents. */}
					<span
						className='hidden sm:inline-block sm:align-middle sm:h-screen'
						aria-hidden='true'
					>
						&#8203;
					</span>
					<Transition.Child
						as={Fragment}
						enter='ease-out duration-300'
						enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
						enterTo='opacity-100 translate-y-0 sm:scale-100'
						leave='ease-in duration-200'
						leaveFrom='opacity-100 translate-y-0 sm:scale-100'
						leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
					>
						{/* Modal Container */}
						<div className='inline-block align-bottom rounded-lg text-left shadow-xl transform transition-all sm:align-middle max-w-full md:w-11/12 md:max-w-2xl lg:max-w-6xl my-20 md:mt-0 mb-auto max-w'>
							<div className='bg-white px-4 py-3 sm:px-6'>
								<div className='flex text-base border-b-2 border-gray-200 text-center mx-auto'>
									<h2 className='text-center font-medium text-gray-500 uppercase tracking-wider py-1 md:text-2xl'>
										{title}
									</h2>
									<div
										className='ml-auto'
										onClick={handleClose}
										ref={cancelButtonRef}
									>
										<CloseIcon styles='text-gray-700 hover:text-green-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500' />
									</div>
								</div>
							</div>
							<div className='bg-white overflow-auto'>{children}</div>
						</div>
					</Transition.Child>
				</div>
			</Dialog>
		</Transition.Root>
	);
};

export default Modal;
