const TableRow = ({ children, styles, handleClick, key }) => {
	return (
		<tr
			className={`${styles} transition duration-200 ease-in-out focus:shadow-md hover:shadow-md`}
			onClick={handleClick}
			key={key}
		>
			{children}
		</tr>
	);
};

const TableData = ({ children, styles, width }) => {
	return (
		<td
			className={`px-6 py-4 text-gray-900 text-center cursor-pointer ${styles} ${
				width ? width : 'w-1/6'
			}`}
		>
			{children}
		</td>
	);
};

//TableRow container
const TableContainer = ({ children, styles }) => {
	return (
		<div className={`text-sm text-gray-900 capitalize ${styles}`}>
			{children}
		</div>
	);
};

TableRow.displayName = 'TableRow';
TableData.displayName = 'TableData';
TableContainer.displayName = 'TableContainer';
TableRow.TableData = TableData;
TableRow.TableContainer = TableContainer;

export const SkeletonTableRow = ({ children }) => {
	return (
		<tr className='animate-pulse bg-gray-200 transition-all duration-300 ease-in-out'>
			{children}
		</tr>
	);
};

export const SkeletonTableData = ({ children, width }) => {
	return <td className={`px-6 py-4 ${width ? width : 'w-1/6'}`}>{children}</td>;
};

export const SkeletonTableContainer = () => {
	return (
		<div className='text-sm text-gray-900 capitalize h-4 bg-gray-300 animate-pulse'></div>
	);
};

SkeletonTableRow.displayName = 'SkeletonTableRow';
SkeletonTableData.displayName = 'SkeletonTableData';
SkeletonTableContainer.displayName = 'SkeletonTableContainer';
SkeletonTableRow.SkeletonTableData = SkeletonTableData;
SkeletonTableRow.SkeletonTableContainer = SkeletonTableContainer;

export default TableRow;
