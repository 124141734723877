import { HEADER_X_API_KEY } from '../util/strings';

const inventoryService = {
	fetchInventoryForFacility: async (idToken, facilityID) => {
		const res = await fetch(
			`${process.env.REACT_APP_API_ENDPOINT_BASE}/inventory?facility_id=${facilityID}`,
			{
				headers: {
					[HEADER_X_API_KEY]: idToken.idToken,
				},
			}
		);

		return await res.json();
	},
	fetchMoreInventoryForFacility: async (
		idToken,
		nextPageNumber,
		facilityID
	) => {
		const res = await fetch(
			`${process.env.REACT_APP_API_ENDPOINT_BASE}/inventory?facility_id=${facilityID}&page=${nextPageNumber}`,
			{
				headers: {
					[HEADER_X_API_KEY]: idToken.idToken,
				},
			}
		);

		return await res.json();
	},
	fetchInventoryForItem: async (idToken, itemID) => {
		const res = await fetch(
			`${process.env.REACT_APP_API_ENDPOINT_BASE}/inventory?item_id=${itemID}`,
			{
				headers: {
					[HEADER_X_API_KEY]: idToken.idToken,
				},
			}
		);

		return await res.json();
	},
	fetchMoreInventoryForItem: async (idToken, nextPageNumber, itemID) => {
		const res = await fetch(
			`${process.env.REACT_APP_API_ENDPOINT_BASE}/inventory?item_id=${itemID}&page=${nextPageNumber}`,
			{
				headers: {
					[HEADER_X_API_KEY]: idToken.idToken,
				},
			}
		);

		return await res.json();
	},
};

export default inventoryService;
