import { useHistory } from 'react-router-dom';

import ChevronRightIcon from '../../../../components/Icons/ChevronRightIcon';
import renderSkeletonListItems from '../../../../util/renderSkeletonListItems';
import TableRow, {
	SkeletonTableRow,
} from '../../../../components/TableRow/TableRow';

const ListItem = ({ item }) => {
	const history = useHistory();

	const onItemClick = (item) =>
		history.push(`/item-inventory/item/${item.name}`, { item });

	return (
		<TableRow key={item.id} handleClick={() => onItemClick(item)}>
			<TableRow.TableData styles='w-4/12 '>
				<TableRow.TableContainer styles='text-left'>
					{item.name ? item.name : 'N/A'}
				</TableRow.TableContainer>
			</TableRow.TableData>

			<TableRow.TableData styles='w-2/12'>
				<TableRow.TableContainer>
					{item.brand ? item.brand : 'N/A'}
				</TableRow.TableContainer>
			</TableRow.TableData>

			<TableRow.TableData styles='w-4/12'>
				<TableRow.TableContainer styles='text-left w-11/12'>
					{item.retail_name ? item.retail_name : 'N/A'}
				</TableRow.TableContainer>
			</TableRow.TableData>

			<TableRow.TableData styles='w-2/12'>
				<ChevronRightIcon styles='ml-auto text-gray-400 hover:text-green-400' />
			</TableRow.TableData>
		</TableRow>
	);
};

export const ListItemSkeleton = ({ idx }) => {
	return (
		<SkeletonTableRow
			key={idx}
			className='animate-pulse bg-gray-200 transition-all duration-300 ease-in-out'
		>
			{renderSkeletonListItems(4)}
		</SkeletonTableRow>
	);
};

export default ListItem;
