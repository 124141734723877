import { HEADER_X_API_KEY } from '../util/strings';

const paymentsService = {
	submitNewPayReq: async (idToken, payReq) => {
		let url = `${process.env.REACT_APP_API_ENDPOINT_BASE}/payment-requests`;
		const res = await fetch(url, {
			method: 'POST',
			headers: {
				[HEADER_X_API_KEY]: idToken.idToken,
			},
			body: JSON.stringify(payReq),
		});

		return await res.json();
	},
	getPayReqStatus: async (idToken, orderNum) => {
		const res = await fetch(
			`${process.env.REACT_APP_API_ENDPOINT_BASE}/payment-requests?order_number=${orderNum}`,
			{
				headers: {
					[HEADER_X_API_KEY]: idToken.idToken,
				},
			}
		);

		return await res.json();
	},
};

export default paymentsService;
