import { HEADER_X_API_KEY } from '../util/strings';

const printOrdersService = {
	printOrders: async (orderID, idToken) => {
		const res = await fetch(
			`${process.env.REACT_APP_API_ENDPOINT_BASE}/orders/reprint?magento_order_id=${orderID}`,
			{
				method: 'POST',
				headers: {
					[HEADER_X_API_KEY]: idToken.idToken,
				},
			}
		);
		return res.json();
	},
};

export default printOrdersService;
