import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import itemsService from '../../../services/items';
import debouncePromise from '../../../util/debouncePromise';
import { COMMON_QUERY_PARAM_OPTIONS } from '../../../util/constants';
import {
	ERROR_GENERIC,
	NO_RESULTS,
	NO_MORE_RESULTS,
} from '../../../util/strings';

const initialState = {
	items: { results: [] },
	itemsMsg: null,
	isItemsLoading: false,
	isMoreItemsLoading: false,
	searchQuery: '',
	searchQueryParam: COMMON_QUERY_PARAM_OPTIONS.find(
		(option) => option.value === 'name'
	).value,
};

const _fetchItemsDebounced = debouncePromise(
	500,
	async (idToken, searchQuery, searchQueryParam) => {
		return await itemsService.fetchItems(
			idToken,
			searchQuery,
			searchQueryParam
		);
	}
);

export const fetchItems = createAsyncThunk(
	'items/fetchItems',
	async (idToken, { getState }) => {
		const { searchQuery, searchQueryParam } = getState().items;
		return await _fetchItemsDebounced(idToken, searchQuery, searchQueryParam);
	}
);

export const fetchMoreItems = createAsyncThunk(
	'items/fetchMoreItems',
	async (idToken, { getState, rejectWithValue }) => {
		const { items, searchQuery, searchQueryParam } = getState().items;

		if (items.results?.length && !items.meta?.next_page) {
			return rejectWithValue(NO_MORE_RESULTS);
		}

		const nextPageNumber = /[0-9]+$/.exec(items.meta.next_page);

		return await itemsService.fetchMoreItems(
			idToken,
			nextPageNumber,
			searchQuery,
			searchQueryParam
		);
	},
	{
		condition: (_, { getState }) => {
			const { isMoreItemsLoading, items } = getState().items;
			if (isMoreItemsLoading || !items.results?.length) {
				return false;
			}
		},
	}
);

export const updateSearchQuery = createAsyncThunk(
	'items/updateSearchQuery',
	async ({ idToken, searchQuery }, { dispatch }) => {
		dispatch(_updateSearchQuery(searchQuery));
		dispatch(fetchItems(idToken));
	}
);

export const updateSearchQueryParam = createAsyncThunk(
	'items/updateSearchQueryParam',
	async ({ idToken, searchQueryParam }, { dispatch, getState }) => {
		const { searchQuery } = getState().items;

		// Re-fetch items only if a query is present
		dispatch(_updateSearchQueryParam(searchQueryParam));
		if (searchQuery) {
			dispatch(fetchItems(idToken));
		}
	}
);

export const itemsSlice = createSlice({
	name: 'items',
	initialState,
	reducers: {
		_updateSearchQuery: (state, action) => {
			state.searchQuery = action.payload;
		},
		_updateSearchQueryParam: (state, action) => {
			state.searchQueryParam = action.payload;
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(fetchItems.pending, (state) => {
				state.isItemsLoading = true;
				state.itemsMsg = null;
			})

			.addCase(fetchItems.fulfilled, (state, action) => {
				state.isItemsLoading = false;
				const json = action.payload;
				if (!json?.results?.length) {
					state.itemsMsg = NO_RESULTS;
					state.items = { results: [] };
				} else {
					state.items = json;

					if (!json?.meta?.next_page) {
						state.itemsMsg = NO_MORE_RESULTS;
					}
				}
			})

			.addCase(fetchItems.rejected, (state) => {
				state.isFacilitiesLoading = false;
				state.itemsMsg = ERROR_GENERIC;
			})

			.addCase(fetchMoreItems.pending, (state) => {
				state.isMoreItemsLoading = true;
				state.itemsMsg = null;
			})

			.addCase(fetchMoreItems.fulfilled, (state, action) => {
				state.isMoreItemsLoading = false;
				const json = action.payload;
				const newItems = json?.results ?? [];
				state.items = {
					...json,
					results: [...state.items.results, ...newItems],
				};
			})

			.addCase(fetchMoreItems.rejected, (state, action) => {
				state.isMoreItemsLoading = false;
				state.itemsMsg = action.payload || ERROR_GENERIC;
			});
	},
});

export const { _updateSearchQuery, _updateSearchQueryParam } =
	itemsSlice.actions;
