import Modal from '../../../components/Modal/Modal';
import Card from '../../../components/Card/Card';

const PaymentsModal = ({ payReqArr, handleClose, showModal }) => {
	const usdFormatter = new Intl.NumberFormat('en-US', {
		style: 'currency',
		currency: 'USD',
	});

	const order = (payment, idx) => (
		<Card
			title={`Order #: ${payment.order_number}`}
			styles='m-5 border border-gray-200'
			key={`${payment.order_number}-${idx}`}
		>
			<div className='grid md:grid-cols-2 md:grid-rows-2 gap-4 p-5'>
				<Card title='Customer Information' styles='border border-gray-200 h-72'>
					<table className='min-w-full divide-y divide-gray-200'>
						<tbody className='text-sm divide-y divide-gray-200 text-left'>
							<tr className=''>
								<td className='py-2 capitalize font-bold pl-5'>Name</td>
								<td className='py-2 capitalize text-gray-600'>
									{payment.first_name
										? `${payment.first_name} ${payment.last_name}`
										: 'N/A'}
								</td>
							</tr>
							<tr className=''>
								<td className='py-2 capitalize font-bold pl-5'>Email</td>
								<td className='py-2 text-gray-600'>
									{payment.email_address ? `${payment.email_address}` : 'N/A'}
								</td>
							</tr>

							<tr className=''>
								<td className='py-2 capitalize font-bold pl-5'>Phone</td>
								<td className='py-2 text-gray-600'>
									{payment.phone_number ? `${payment.phone_number}` : 'N/A'}
								</td>
							</tr>
						</tbody>
					</table>
				</Card>
				<Card
					title='Transactions'
					styles='border border-gray-200 overflow-auto h-72'
				>
					{payment.transactions.length > 0 ? (
						payment.transactions.map((transaction, idx) => (
							<Card styles='m-5' title={`Transaction #: ${idx + 1}`}>
								<table className='min-w-full divide-y divide-gray-200'>
									<tbody className='text-sm divide-y divide-gray-200 text-left'>
										<tr className=''>
											<td className='py-2 capitalize font-bold pl-5'>Amount</td>
											<td className='py-2 capitalize text-gray-600'>
												{transaction.amount
													? `${usdFormatter.format(transaction.amount / 100)}`
													: 'N/A'}
											</td>
										</tr>
										<tr className=''>
											<td className='py-2 capitalize font-bold pl-5'>
												Created At
											</td>
											<td className='py-2 text-gray-600'>
												{transaction.created_at
													? `${new Date(
															Date.parse(transaction.created_at)
													  ).toUTCString()}`
													: 'N/A'}
											</td>
										</tr>

										<tr className=''>
											<td className='py-2 capitalize font-bold pl-5'>
												Processor
											</td>
											<td className='py-2 text-gray-600 capitalize'>
												{transaction.processor
													? `${transaction.processor}`
													: 'N/A'}
											</td>
										</tr>
										<tr className=''>
											<td className='py-2 capitalize font-bold pl-5'>
												Processor ID
											</td>
											<td className='py-2 text-gray-600 capitalize'>
												{transaction.processor_id
													? `${transaction.processor_id}`
													: 'N/A'}
											</td>
										</tr>
										<tr className=''>
											<td className='py-2 capitalize font-bold pl-5'>Status</td>
											<td className='py-2 text-gray-600 capitalize'>
												{transaction.status ? `${transaction.status}` : 'N/A'}
											</td>
										</tr>
									</tbody>
								</table>
							</Card>
						))
					) : (
						<h1 className='text-gray-400 uppercase text-center my-10'>
							No transactions to display
						</h1>
					)}
				</Card>
				<Card
					title='Order Information'
					styles='border border-gray-200 min-w-0 min-h-0 overflow-y-auto h-72'
				>
					<table className='min-w-full divide-y divide-gray-200'>
						<tbody className='text-sm divide-y divide-gray-200 text-left'>
							<tr className=''>
								<td className='py-2 capitalize font-bold pl-5'>Amount</td>
								<td className='py-2 capitalize text-gray-600'>
									{payment.amount
										? usdFormatter.format(payment?.amount / 100)
										: 'N/A'}
								</td>
							</tr>
							<tr className=''>
								<td className='py-2 capitalize font-bold pl-5'>Order Type</td>
								<td className='py-2 capitalize text-gray-600'>
									{payment.order_type ? payment.order_type : 'N/A'}
								</td>
							</tr>
							<tr className=''>
								<td className='py-2 capitalize font-bold pl-5'>Order Date</td>
								<td className='py-2 capitalize text-gray-600'>
									{payment.order_date
										? new Date(Date.parse(payment?.order_date)).toUTCString()
										: 'N/A'}
								</td>
							</tr>
							<tr className=''>
								<td className='py-2 capitalize font-bold pl-5'>
									Last Updated At
								</td>
								<td className='py-2 capitalize text-gray-600'>
									{payment.updated_at
										? new Date(Date.parse(payment?.updated_at)).toUTCString()
										: 'N/A'}
								</td>
							</tr>
							<tr className=''>
								<td className='py-2 capitalize font-bold pl-5'>
									Order Facility
								</td>
								<td className='py-2 capitalize text-gray-600'>
									{payment.facility.name ? `${payment.facility.name}` : 'N/A'}
								</td>
							</tr>
							<tr className=''>
								<td className='py-2 capitalize font-bold pl-5'>
									Facility Address
								</td>
								<td className='py-2 capitalize text-gray-600'>
									{payment.facility.name
										? `${payment.facility.street_address_1}, ${payment.facility.city}, ${payment.facility.state}`
										: 'N/A'}
								</td>
							</tr>
						</tbody>
					</table>
				</Card>
				<Card title='Order Status' styles='border border-gray-200 h-72'>
					<div className='flex items-center h-56'>
						<div
							className={`max-sm:w-11/12 md:w-6/12 mx-auto max-sm:py-8 md:p-5 text-center flex items-center rounded-lg ${
								payment.status === 'paid'
									? 'bg-green-100'
									: payment.status === 'unpaid'
									? 'bg-red-100'
									: payment.status === 'expired'
									? 'bg-yellow-100'
									: null
							}`}
						>
							<h1
								className={`mx-auto text-2xl lg:text-3xl p-0 leading-5 font-semibold uppercase ${
									payment.status === 'paid'
										? 'text-green-800'
										: payment.status === 'unpaid'
										? 'text-red-800'
										: payment.status === 'expired'
										? 'text-yellow-800'
										: null
								}`}
							>
								{payment.status}
							</h1>
						</div>
					</div>
				</Card>
			</div>
		</Card>
	);

	return (
		<Modal
			title='Order Details'
			showModal={showModal}
			handleClose={handleClose}
		>
			{payReqArr.map((payment, idx) => order(payment, idx))}
		</Modal>
	);
};

export default PaymentsModal;
