export const NO_RESULTS = 'No results';
export const ERROR_GENERIC =
	'There was an unexpected error. Please contact support if this issue persists.';
export const SUCCESS_GENERIC = 'Success!';
export const NO_MORE_RESULTS = 'No more results';

export const PAYMENT_REQUEST_MISSING_FIELD =
	'Either email or mobile phone is required';
export const PAYMENT_REQUEST_NO_MATCH =
	'No matching payment request was found.';

export const HEADER_X_API_KEY = 'x-api-key';
