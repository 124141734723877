import { useOktaAuth } from '@okta/okta-react';
import { Fragment, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { MenuAlt2Icon, XIcon } from '@heroicons/react/outline';
import NavigationBar from '../NavigationBar/NavigationBar';

export default function Layout({ children }) {
	const { authState } = useOktaAuth();
	const [sidebarOpen, setSidebarOpen] = useState(false);

	return (
		<>
			{/* Mobile SideBar */}
			<div>
				<Transition.Root show={sidebarOpen} as={Fragment}>
					<Dialog
						as='div'
						className='fixed inset-0 z-40 flex md:hidden'
						onClose={setSidebarOpen}
					>
						<Transition.Child
							as={Fragment}
							enter='transition-opacity ease-linear duration-300'
							enterFrom='opacity-0'
							enterTo='opacity-100'
							leave='transition-opacity ease-linear duration-300'
							leaveFrom='opacity-100'
							leaveTo='opacity-0'
						>
							<Dialog.Overlay className='fixed inset-0 bg-gray-600 bg-opacity-75' />
						</Transition.Child>
						<Transition.Child
							as={Fragment}
							enter='transition ease-in-out duration-300 '
							enterFrom='-translate-x-full'
							enterTo='translate-x-0'
							leave='transition ease-in-out duration-300 '
							leaveFrom='translate-x-0'
							leaveTo='-translate-x-full'
						>
							<div className='relative max-w-xs w-full flex-1 flex flex-col'>
								<Transition.Child
									as={Fragment}
									enter='ease-in-out duration-300'
									enterFrom='opacity-0'
									enterTo='opacity-100'
									leave='ease-in-out duration-300'
									leaveFrom='opacity-100'
									leaveTo='opacity-0'
								>
									<div className='absolute top-0 right-0 -mr-12 pt-2'>
										<button
											type='button'
											className='ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white'
											onClick={() => setSidebarOpen(false)}
										>
											<span className='sr-only'>Close sidebar</span>
											<XIcon
												className='h-6 w-6 text-white'
												aria-hidden='true'
											/>
										</button>
									</div>
								</Transition.Child>
								{authState?.isAuthenticated && (
									<NavigationBar handleClick={() => setSidebarOpen(false)} />
								)}
							</div>
						</Transition.Child>
						<div className='flex-shrink-0 w-14'>
							{/* Dummy element to force sidebar to shrink to fit close icon */}
						</div>
					</Dialog>
				</Transition.Root>
				{/* End mobile sidebar */}

				{/* Static sidebar for desktop */}
				<div className='hidden md:flex md:w-48 lg:w-56 md:flex-col md:fixed md:inset-y-0'>
					{authState?.isAuthenticated && <NavigationBar />}
				</div>
				{/* End navigation */}

				<div className='md:pl-48 lg:pl-56'>
					<div className='mx-auto flex flex-col xl:px-0 md:h-screen md:border-l md:border-gray-500 overflow-y-auto'>
						<div className='max-md:sticky max-md:top-0 max-md:z-10 max-md:flex-shrink-0 max-md:h-16 max-md:bg-white max-md:border-b max-md:border-gray-200 flex'>
							<button
								type='button'
								className='border-r border-gray-200 px-4 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500 md:hidden'
								onClick={() => setSidebarOpen(true)}
							>
								<span className='sr-only'>Open sidebar</span>
								<MenuAlt2Icon className='h-6 w-6' aria-hidden='true' />
							</button>
						</div>

						<main className='flex-1'>
							<div className='py-6 overflow-x-auto'>
								<div className='md:px-0'>{children}</div>
							</div>
						</main>
					</div>
				</div>
			</div>
		</>
	);
}
