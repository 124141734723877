import React, { useEffect } from 'react';
import { useOktaAuth } from '@okta/okta-react';

const LoginSSO = () => {
	const { oktaAuth, authState } = useOktaAuth();

	useEffect(() => {
		oktaAuth.signInWithRedirect();
	}, [oktaAuth]);

	return authState ? null : (
		<div>
			<p>Logging in&#8230;</p>
		</div>
	);
};

export default LoginSSO;
