import { configureStore } from '@reduxjs/toolkit';

import { itemsSlice } from '../features/Inventory/Items/itemsState';
import { paymentsSlice } from '../features/Payments/paymentsState';
import { inventorySlice } from '../features/Inventory/inventoryState';
import { facilitiesSlice } from '../features/Inventory/Facility/facilitiesState';
import { searchbarSlice } from '../features/SearchBar/searchBarState';
import printOrdersSlice from '../features/PrintOrders/printOrdersState';
import navigationBarSlice from '../components/NavigationBar/navigationbarState';
import { customersSlice } from '../features/Customers/customersState';

const store = configureStore({
  reducer: {
    inventory: inventorySlice.reducer,
    items: itemsSlice.reducer,
    facilities: facilitiesSlice.reducer,
    payments: paymentsSlice.reducer,
    searchBar: searchbarSlice.reducer,
    printOrders: printOrdersSlice,
    navigation: navigationBarSlice,
    customers: customersSlice.reducer,
  },
});

export default store;
