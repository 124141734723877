const byAsc = (a, b) =>
	a.text.toLowerCase().localeCompare(b.text.toLowerCase());

export const COMMON_QUERY_PARAM_OPTIONS = [
	{ key: 'name', text: 'Name', value: 'name' },
	{ key: 'brand', text: 'Brand', value: 'brand' },
	{ key: 'part_number', text: 'Part Number', value: 'part_number' },
	{ key: 'retail_name', text: 'Retail Name', value: 'retail_name' },
];

export const COMMON_BRAND_OPTIONS = [
	{ key: 'neta', value: 'neta', text: 'NETA' },
	{ key: 'surterra', value: 'surterra', text: 'Surterra' },
].sort(byAsc);

export const COMMON_ORDER_TYPE_OPTIONS = [
	{ key: 'curbside', value: 'curbside', text: 'Curbside' },
	{ key: 'delivery', value: 'delivery', text: 'Delivery' },
	{ key: 'in-store', value: 'in-store', text: 'In-store' },
	{ key: 'pickup', value: 'pickup', text: 'Pickup' },
].sort(byAsc);

export const PATTERN_COMMON_DATE =
	'\\d{4}-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])$';
export const KEY_USER_FACILITY_ID = 'userPreferredFacilityID';
export const KEY_USER_BRAND_NAME = 'userPreferredBrand';
