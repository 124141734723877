import Input from '../Input/Input';
import Label from '../Label';

export default function CompoundSearch(props) {
  const fields = props.fields;

  function clearFields() {
    fields.map((field) => {
      document.getElementById(field.id).value = '';
      return true;
    });
    props.handleClick('clear');
  }

  return (
    <div className='border-t border-b border-gray-400 px-4 py-6'>
      <div className='flex flex-row justify-between'>
        {fields.map((field, i) => (
          <div key={i} className='flex flex-col'>
            <Label htmlFor={`search-field-input-${field.name}`}>
              {field.name}
            </Label>

            <div className='mt-1 md:w-11/12 xxl:w-full'>
              <Input
                type={field.type}
                name={field.name}
                placeholder={field.name}
                onChange={(e) => props.handleInput(e.target.id, e.target.value)}
                id={field.id}
              />
            </div>
          </div>
        ))}

        <div className='mt-auto'>
          <button
            className={`inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-700 hover:bg-blue-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 py-0 leading-8`}
            onClick={() => props.handleClick('search')}
          >
            Search
          </button>
        </div>
        <div className="mt-auto">
          <button
            className={`inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-700 hover:bg-blue-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 py-0 leading-8 ml-1`}
            onClick={() => clearFields()}
          >
            Clear
          </button>
        </div>
      </div>
    </div>
  );
}
