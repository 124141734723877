import AlertIcon from '../Icons/AlertIcon';
import CheckIcon from '../Icons/CheckIcon';

const Alert = ({ children, setAlertState, type }) => {
	return (
		<div
			className={`px-2 py-4 md:px-6 md:py-4 border-0 rounded relative ${
				type === 'success'
					? 'bg-green-300'
					: type === 'fail'
					? 'bg-red-300'
					: type === 'notify'
					? 'bg-blue-200'
					: 'bg-red-300'
			}`}
		>
			<span className='text-xl inline-block mr-5 align-middle'>
				{type === 'success' ? (
					<CheckIcon />
				) : type === 'error' ? (
					<AlertIcon />
				) : (
					<AlertIcon />
				)}
			</span>
			<span className='inline-block align-middle mr-8'>{children}</span>
			<button className='absolute bg-transparent text-2xl font-semibold leading-none right-0 top-0 mt-4 mr-6 outline-none focus:outline-none'>
				<span onClick={() => setAlertState(false)}>×</span>
			</button>
		</div>
	);
};

export default Alert;
