import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import paymentsService from '../../services/payments';
import {
	KEY_USER_FACILITY_ID,
	KEY_USER_BRAND_NAME,
	COMMON_BRAND_OPTIONS,
	COMMON_ORDER_TYPE_OPTIONS,
} from '../../util/constants';
import {
	ERROR_GENERIC,
	PAYMENT_REQUEST_MISSING_FIELD,
	SUCCESS_GENERIC,
	PAYMENT_REQUEST_NO_MATCH,
} from '../../util/strings';

const defaultOrderType = COMMON_ORDER_TYPE_OPTIONS.find(
	(option) => option.value === 'pickup'
).value;

export const initialState = {
	amountForPost: '',
	facilityForPost: '',
	lastNameForPost: '',
	orderDateForPost: '',
	orderTypeForPost: defaultOrderType,
	firstNameForPost: '',
	orderNumberForPost: '',
	retailBrandForPost: '',
	phoneNumberForPost: '',
	emailAddressForPost: '',
	createPayReqMsg: null,
	paymentSuccess: false,
	paymentError: false,
	isCreatePayReqLoading: false,
	payReqArr: null,
	payReqErrMsg: '',
	payReqOrderNum: '',
	isPayReqLoading: false,
	showPaymentModal: false,
	submitPayReqError: false,
};

export const submitNewPayReq = createAsyncThunk(
	'payments/submitNewPayReq',
	async (idToken, { getState, rejectWithValue }) => {
		const { payments } = getState();

		// Assert presence of at least email or phone
		if (!payments.emailAddressForPost && !payments.phoneNumberForPost) {
			return rejectWithValue(PAYMENT_REQUEST_MISSING_FIELD);
		}

		const payReq = {
			retail_brand: payments.retailBrandForPost,
			facility: payments.facilityForPost,
			order_number: payments.orderNumberForPost,
			order_date: payments.orderDateForPost,
			order_type: payments.orderTypeForPost,
			first_name: payments.firstNameForPost,
			last_name: payments.lastNameForPost,
			email_address: payments.emailAddressForPost,
			phone_number: payments.phoneNumberForPost,
			amount: Number.parseInt(payments.amountForPost * 100),
		};

		return await paymentsService.submitNewPayReq(idToken, payReq);
	},
	{
		condition: (_, { getState }) => {
			const { isCreatePayReqLoading } = getState().payments;
			if (isCreatePayReqLoading) {
				return false;
			}
		},
	}
);

export const getPayReqStatus = createAsyncThunk(
	'payments/getPayReqStatus',
	async (search, { getState }) => {
		// const { payReqOrderNum } = getState().payments;
		return await paymentsService.getPayReqStatus(
			search?.idToken,
			search.searchQuery
		);
	},
	{
		condition: (_, { getState }) => {
			const { isPayReqLoading } = getState().payments;
			if (isPayReqLoading) {
				return false;
			}
		},
	}
);

export const paymentsSlice = createSlice({
	name: 'payments',
	initialState,
	reducers: {
		setShowPaymentModal: (state, action) => {
			state.setShowPaymentModal = action.payload;
		},
		populateRecentInputs: (state, action) => {
			const facilities = action.payload;

			//TODO simplify this or take out this part and create another reducer for it.
			// Restore any user settings for preferred facility
			const preferredFacilityID =
				window.localStorage.getItem(KEY_USER_FACILITY_ID);
			const facilityIDInList = facilities?.results?.find(
				(facility) => facility.id === preferredFacilityID
			);
			if (preferredFacilityID && facilityIDInList) {
				state.facilityForPost = preferredFacilityID;
			} else {
				state.facilityForPost = facilities.results[0].id;
			}

			//TODO simplify this or take out this part and create another reducer for it.
			// Restore any user settings for preferred brand
			const preferredBrandName =
				window.localStorage.getItem(KEY_USER_BRAND_NAME);
			const isRetailBrandInList = COMMON_BRAND_OPTIONS.map(
				(option) => option.key
			).includes(preferredBrandName);
			if (preferredBrandName && isRetailBrandInList) {
				state.retailBrandForPost = preferredBrandName;
			} else {
				state.retailBrandForPost = COMMON_BRAND_OPTIONS[0].key;
			}
		},
		setAmountForPost: (state, action) => {
			state.amountForPost = action.payload;
		},
		setFacilityForPost: (state, action) => {
			state.facilityForPost = action.payload;
			window.localStorage.setItem(KEY_USER_FACILITY_ID, action.payload);
		},
		setLastNameForPost: (state, action) => {
			state.lastNameForPost = action.payload;
		},
		setOrderDateForPost: (state, action) => {
			state.orderDateForPost = action.payload;
		},
		setFirstNameForPost: (state, action) => {
			state.firstNameForPost = action.payload;
		},
		setOrderNumberForPost: (state, action) => {
			state.orderNumberForPost = action.payload;
		},
		setCreatePayReqMsg: (state, action = null) => {
			state.createPayReqMsg = action.payload || null;
		},
		setPayReqArr: (state, action = null) => {
			state.payReqArr = action.payload || null;
		},
		setPayReqErrMsg: (state, action = null) => {
			state.payReqErrMsg = action.payload || null;
		},
		setPayReqOrderNum: (state, action) => {
			state.payReqOrderNum = action.payload;
		},
		setRetailBrandForPost: (state, action) => {
			state.retailBrandForPost = action.payload;
			window.localStorage.setItem(KEY_USER_BRAND_NAME, action.payload);
		},
		setPhoneNumberForPost: (state, action) => {
			state.phoneNumberForPost = action.payload;
		},
		setEmailAddressForPost: (state, action) => {
			state.emailAddressForPost = action.payload;
		},
		setOrderTypeForPost: (state, action) => {
			state.orderTypeForPost = action.payload;
		},
		setPaymentSuccess: (state, action) => {
			state.paymentSuccess = action.payload;
		},
		setPaymentError: (state, action) => {
			state.paymentError = action.payload;
		},
		setSubmitPayReqError: (state, action) => {
			state.submitPayReqError = action.payload;
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(submitNewPayReq.pending, (state) => {
				state.createPayReqMsg = null;
				state.isCreatePayReqLoading = true;
			})

			.addCase(submitNewPayReq.fulfilled, (state, action) => {
				state.isCreatePayReqLoading = false;
				const json = action.payload;

				if (json.message) {
					state.createPayReqMsg =
						json.message === 'Validation Failed'
							? 'Form Validation Failed. Please make sure all fields below are filled out, and filled out correctly.'
							: ERROR_GENERIC;
					state.submitPayReqError = true;
				} else {
					state.createPayReqMsg = SUCCESS_GENERIC;
					state.paymentSuccess = true;

					// We want to keep only retail_brand and facility between successful submissions
					state.amountForPost = '';
					state.lastNameForPost = '';
					state.orderTypeForPost = defaultOrderType;
					state.orderDateForPost = '';
					state.firstNameForPost = '';
					state.orderNumberForPost = '';
					state.phoneNumberForPost = '';
					state.emailAddressForPost = '';
				}
			})

			.addCase(submitNewPayReq.rejected, (state, action) => {
				state.isCreatePayReqLoading = false;
				state.createPayReqMsg = ERROR_GENERIC;
				state.submitPayReqError = true;
			})

			.addCase(getPayReqStatus.pending, (state, action) => {
				state.paymentError = false;
				state.payReqErrMsg = null;
				state.isPayReqLoading = true;
			})

			.addCase(getPayReqStatus.fulfilled, (state, action) => {
				state.isPayReqLoading = false;
				const json = action.payload;
				const payReqArr = json?.results;
				state.payReqArr = payReqArr;

				if (payReqArr?.length) {
					state.setShowPaymentModal = true;
				} else {
					state.payReqErrMsg = PAYMENT_REQUEST_NO_MATCH;
					state.paymentError = true;
				}
			})

			.addCase(getPayReqStatus.rejected, (state, action) => {
				state.isPayReqLoading = false;
				state.payReqErrMsg = ERROR_GENERIC;
				state.paymentError = true;
			});
	},
});

export const {
	setShowPaymentModal,
	populateRecentInputs,
	setAmountForPost,
	setFacilityForPost,
	setLastNameForPost,
	setOrderDateForPost,
	setFirstNameForPost,
	setOrderNumberForPost,
	setRetailBrandForPost,
	setPayReqArr,
	setPayReqErrMsg,
	setPayReqOrderNum,
	setPhoneNumberForPost,
	setEmailAddressForPost,
	setOrderTypeForPost,
	setCreatePayReqMsg,
	setPaymentSuccess,
	setPaymentError,
	setSubmitPayReqError,
} = paymentsSlice.actions;
