import { Fragment, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useOktaAuth } from '@okta/okta-react';
import {
	setShowSuccessAlert,
	setShowErrorAlert,
	fetchPrintOrder,
	setOrderID,
	setResponse,
	clearAlerts,
} from './printOrdersState';
import { Transition } from '@headlessui/react';

import Label from '../../components/Label';
import Button from '../../components/Button';
import Alert from '../../components/Alert/Alert';
import { TEN_DIGIT_NUMBER } from '../../util/formValidation';
import { selectOrdersView } from '../SearchBar/searchBarState';

export default function PrintOrders() {
	const { authState } = useOktaAuth();
	const idToken = authState?.idToken;
	const dispatch = useDispatch();
	const showSuccessAlert = useSelector(
		({ printOrders }) => printOrders.showSuccessAlert
	);
	const showErrorAlert = useSelector(
		({ printOrders }) => printOrders.showErrorAlert
	);
	const orderId = useSelector(({ printOrders }) => printOrders.orderID);
	const orderLoading = useSelector(
		({ printOrders }) => printOrders.orderLoading
	);
	const response = useSelector(({ printOrders }) => printOrders.response);

	const successAlert = (
		<Transition
			as={Fragment}
			show={showSuccessAlert}
			enter=' transition duration-[400ms]'
			enterFrom='opacity-0  scale-50'
			enterTo='opacity-100  scale-100'
			leave=' duration-200 transition ease-in-out'
			leaveFrom='opacity-100  scale-100 '
			leaveTo='opacity-0 scale-95 '
		>
			<div className='mb-2'>
				<Alert
					type='success'
					setAlertState={() => dispatch(setShowSuccessAlert(false))}
				>
					{response}
				</Alert>
			</div>
		</Transition>
	);

	const errorAlert = (
		<Transition
			as={Fragment}
			show={showErrorAlert}
			enter=' transition duration-[400ms]'
			enterFrom='opacity-0  scale-50'
			enterTo='opacity-100  scale-100'
			leave=' duration-200 transition ease-in-out'
			leaveFrom='opacity-100  scale-100 '
			leaveTo='opacity-0 scale-95 '
		>
			<div className='mb-2'>
				<Alert
					type='error'
					setAlertState={() => dispatch(setShowErrorAlert(false))}
				>
					{response}
				</Alert>
			</div>
		</Transition>
	);

	const handleSubmit = (e) => {
		if (orderId.length === 10) {
			e.preventDefault();
			dispatch(setResponse(''));
			dispatch(fetchPrintOrder({ orderId, idToken }));
			dispatch(setOrderID(''));
		} else if (orderId.length) {
			dispatch(setResponse(''));
			dispatch(
				setResponse(
					'Order ID must be exactly 10 characters long and must only include numbers.'
				)
			);
			dispatch(setShowErrorAlert(true));
			dispatch(setOrderID(''));
		}
	};

	//Resets orderID on component did mount. Does not fire when component updates.
	useEffect(() => {
		dispatch(setOrderID(''));
		dispatch(selectOrdersView());
	}, [dispatch]);

	return (
		<div className='mx-4'>
			<div className='shadow sm:rounded-md sm:overflow-hidden'>
				<form
					action='#'
					onSubmit={(e) => {
						e.preventDefault();
						handleSubmit(e);
						return false;
					}}
				>
					<div className='px-4 py-5 bg-white space-y-6 sm:p-6'>
						<div className='sm:px-0 font-sans'>
							<h3 className='md:text-xl font-medium leading-6 text-gray-900'>
								Reprint Magento Order Receipt
							</h3>
							<p className='mt-1 text-sm text-gray-500'>
								Enter a Magento Order ID below to reprint a "New Order" or
								"Cancelled Order" receipt.
							</p>
						</div>

						<div className='grid grid-cols-4 gap-6'>
							<div className='col-span-4 md:col-span-3 font-sans'>
								<div className='md:w-max'>
									{successAlert}
									{errorAlert}
								</div>

								<div className='md:w-4/12'>
									<Label htmlFor='order-receipt' styles='text-base mb-1'>
										Magento Order ID
									</Label>
									<div className='flex rounded-md shadow-sm'>
										<input
											type='text'
											pattern={TEN_DIGIT_NUMBER}
											name='order-id'
											className={`focus:ring-indigo-500 focus:border-indigo-500 flex-1 block w-full rounded-md sm:text-sm md:text-base border border-gray-300 shadow-sm p-1 pl-2 font-sans font-light`}
											placeholder={'10 Digit Order ID'}
											value={orderId}
											onChange={(e) => {
												dispatch(clearAlerts());
												dispatch(setOrderID(e.target.value));
											}}
											required
										/>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div className='px-4 py-3 bg-gray-50 text-left sm:px-6'>
						<div className='w-max'>
							<Button
								styles='text-center mx-auto'
								type='submit'
								loading={orderLoading}
							>
								Print Receipt
							</Button>
						</div>
					</div>
				</form>
			</div>
		</div>
	);
}
