const SellStatusIcon = ({ sellable, notSellable, styles }) => {
	const sellableIcon = (
		<div
			className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800 ${styles}`}
		>
			Sellable
		</div>
	);

	const notSellableIcon = (
		<div
			className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-100 text-red-800 ${styles}`}
		>
			Not Sellable
		</div>
	);
	return (
		<>
			{sellable && sellableIcon}
			{notSellable && notSellableIcon}
		</>
	);
};

export default SellStatusIcon;
