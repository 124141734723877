import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import facilitiesService from '../../../services/facilities';
import {
	ERROR_GENERIC,
	NO_RESULTS,
	NO_MORE_RESULTS,
} from '../../../util/strings';

export const activeView = { FACILITY: 'facility', ITEM: 'item' };

const initialState = {
	facilities: { results: [] },
	facilitiesMsg: null,
	isFacilitiesLoading: false,
	currActiveView: activeView.FACILITY,
};

export const fetchFacilities = createAsyncThunk(
	'facilities/fetchFacilities',
	async (idToken) => {
		const json = await facilitiesService.fetchFacilities(idToken);
		return json;
	}
);

// TODO: store facilities data in state, to eliminate extra api request.
export const facilitiesSlice = createSlice({
	name: 'facilities',
	initialState,
	reducers: {
		selectFacilityView: (state) => {
			state.currActiveView = activeView.FACILITY;
		},
		selectItemView: (state) => {
			state.currActiveView = activeView.ITEM;
		},
		filterFacilities: (state, action) => {
			switch (action.payload) {
				case '':
					state.filteredFacilities = state.facilities;
					break;
				default:
					state.filteredFacilities.results = state.facilities.results.filter(
						(facility) => {
							if (facility.name === null || facility.name === undefined) {
								return;
							}
							return facility.name.toLowerCase().includes(action.payload);
						}
					);
					break;
			}
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(fetchFacilities.pending, (state) => {
				state.isFacilitiesLoading = true;
				state.facilitiesMsg = null;
			})

			.addCase(fetchFacilities.fulfilled, (state, action) => {
				state.isFacilitiesLoading = false;

				// TODO: - Add success checking (4XX will trigger a "No results")
				const json = action.payload;
				if (!json?.results?.length) {
					state.facilitiesMsg = NO_RESULTS;
				} else {
					// Sort by ASC order
					const sortedResults = json.results.sort((a, b) =>
						a.name?.localeCompare(b.name)
					);
					const sortedJson = { ...json, results: sortedResults };
					state.facilities = sortedJson;
					state.filteredFacilities = state.facilities;
					state.facilitiesMsg = NO_MORE_RESULTS;
				}
			})

			.addCase(fetchFacilities.rejected, (state) => {
				state.isFacilitiesLoading = false;
				state.facilitiesMsg = ERROR_GENERIC;
			});
	},
});

export const { selectFacilityView, selectItemView, filterFacilities } =
	facilitiesSlice.actions;
