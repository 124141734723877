import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import App from './App';
import store from './redux/store';
import * as serviceWorker from './serviceWorker';
import './index.css';

console.log(
	`%cRunning Parallel Console Version: 1.2.0`,
	'color: white; font-family:monospace; font-size: 16px; background-color: blue; padding: 2px;'
);

ReactDOM.render(
	// TODO: - Fix deprecation warnings for Strict Mode and findDOMNode
	<React.StrictMode>
		<Provider store={store}>
			<App />
		</Provider>
	</React.StrictMode>,
	document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
