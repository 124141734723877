import { useHistory } from 'react-router-dom';

import TableRow, {
	SkeletonTableRow,
} from '../../../../components/TableRow/TableRow';
import ChevronRightIcon from '../../../../components/Icons/ChevronRightIcon';
import renderSkeletonListItems from '../../../../util/renderSkeletonListItems';

const FacilityListItem = ({ facility }) => {
	const history = useHistory();

	const handleFacilityClick = (facility) => {
		return history.push(`/facility-inventory/facility/${facility.name}`, {
			facility,
		});
	};

	return (
		<TableRow
			reactKey={facility.id}
			handleClick={() => handleFacilityClick(facility)}
		>
			<TableRow.TableData>
				<TableRow.TableContainer styles='w-max capitalize'>
					{facility.name}
				</TableRow.TableContainer>
			</TableRow.TableData>

			<TableRow.TableData>
				<TableRow.TableContainer>
					{facility.facility_type}
				</TableRow.TableContainer>
			</TableRow.TableData>

			<TableRow.TableData>
				<TableRow.TableContainer>{facility.city}</TableRow.TableContainer>
			</TableRow.TableData>

			<TableRow.TableData>
				<TableRow.TableContainer>{facility.state}</TableRow.TableContainer>
			</TableRow.TableData>

			<TableRow.TableData>
				<TableRow.TableContainer>
					{facility.email ? facility.email : 'N/A'}
				</TableRow.TableContainer>
			</TableRow.TableData>

			<TableRow.TableData>
				<ChevronRightIcon styles='ml-auto text-gray-400 hover:text-green-400' />
			</TableRow.TableData>
		</TableRow>
	);
};

export const FacilityListItemSkeleton = ({ idx }) => {
	return (
		<SkeletonTableRow key={idx}>{renderSkeletonListItems(6)}</SkeletonTableRow>
	);
};

export default FacilityListItem;
