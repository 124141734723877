import { Fragment } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useOktaAuth } from '@okta/okta-react';
import { Transition } from '@headlessui/react';

import {
	setSearch,
	setShowAlert,
	setShowPatternAlert,
	setShowCharCountAlert,
} from './searchBarState';
import * as paymentActions from '../../features/Payments/paymentsState';
import { updateSearchQueryParam } from '../../features/Inventory/Items/itemsState';

import SearchIcon from '../../components/Icons/SearchIcon';
import Alert from '../../components/Alert/Alert';

const SearchBar = ({
	handleSearch,
	handleSubmit,
	placeholder,
	queryParamOptions,
}) => {
	const { authState } = useOktaAuth();
	const dispatch = useDispatch();
	const searchQuery = useSelector((state) => state.searchBar.searchQuery);
	const showPatternAlert = useSelector(
		(state) => state.searchBar.showPatternAlert
	);
	const showCharCountAlert = useSelector(
		(state) => state.searchBar.showCharCountAlert
	);
	const isPayReqLoading = useSelector(
		({ payments }) => payments.isPayReqLoading
	);
	const paymentError = useSelector(({ payments }) => payments.paymentError);
	const payReqErrMsg = useSelector(({ payments }) => payments.payReqErrMsg);

	//QueryParams will change when different search bar options is selected.
	const onQueryParamChange = (searchQueryParam) => {
		dispatch(
			updateSearchQueryParam({
				idToken: authState?.idToken,
				searchQueryParam,
			})
		);
	};

	//Chooses how to implement the search based on current view.
	const handleSearchByCurrentView = (e) => {
		dispatch(setSearch(e.target.value));
		handleSearch(e);
	};

	return (
		<div>
			<div className='shadow flex bg-white rounded-sm transition duration-200 ease-in-out focus:shadow-lg hover:shadow-lg'>
				<div className='w-auto flex justify-end items-center text-green-400 p-2 hover:text-green-300'>
					<SearchIcon />
				</div>
				<input
					className='w-full rounded p-2 focus:outline-none max-sm:text-sm text-gray-500 font-sans font-light'
					type='text'
					placeholder={placeholder}
					value={searchQuery}
					onChange={(e) => handleSearchByCurrentView(e)}
					onKeyUp={(e) => {
						if (handleSubmit !== undefined) {
							handleSubmit(e);
						}
					}}
				/>

				{/* Search Option Dropdown */}
				<select
					className='form-select pr-10 mr-2 focus:outline-none max-sm:text-md font-sans font-light'
					onChange={(e) => onQueryParamChange(e.target.value)}
				>
					{renderSearchOptions(queryParamOptions)}
				</select>
			</div>

			<div className='mt-2'>
				{/* Items search special characters error */}
				<Transition
					as={Fragment}
					show={showPatternAlert}
					enter=' transition duration-[400ms]'
					enterFrom='opacity-0 scale-50'
					enterTo='opacity-100 scale-100'
					leave=' duration-200 transition ease-in-out'
					leaveFrom='opacity-100 scale-100 '
					leaveTo='opacity-0 scale-95 '
				>
					<div>
						<Alert setAlertState={() => dispatch(setShowPatternAlert(false))}>
							Search term cannot include any special characters except for :
							"-", "_", and "$".
						</Alert>
					</div>
				</Transition>

				{/* Items search character count error */}
				<Transition
					as={Fragment}
					show={showCharCountAlert}
					enter=' transition duration-[400ms]'
					enterFrom='opacity-0 scale-50'
					enterTo='opacity-100 scale-100'
					leave=' duration-200 transition ease-in-out'
					leaveFrom='opacity-100 scale-100 '
					leaveTo='opacity-0 scale-95 '
				>
					<div>
						<Alert setAlertState={() => dispatch(setShowCharCountAlert(false))}>
							Search term must be less than 70 characters.
						</Alert>
					</div>
				</Transition>

				{/* Payments Search */}
				<Transition
					as={Fragment}
					show={isPayReqLoading}
					enter=' transition duration-[400ms]'
					enterFrom='opacity-0 scale-50'
					enterTo='opacity-100 scale-100'
					leave=' duration-200 transition ease-in-out'
					leaveFrom='opacity-100 scale-100 '
					leaveTo='opacity-0 scale-95 '
				>
					<div>
						<Alert
							setAlertState={() => dispatch(setShowAlert(false))}
							type='notify'
						>
							Searching for that order.
						</Alert>
					</div>
				</Transition>
				{/* Payments search error */}
				<Transition
					as={Fragment}
					show={paymentError}
					enter=' transition duration-[400ms]'
					enterFrom='opacity-0 scale-50'
					enterTo='opacity-100 scale-100'
					leave=' duration-200 transition ease-in-out'
					leaveFrom='opacity-100 scale-100 '
					leaveTo='opacity-0 scale-95 '
				>
					<div className=''>
						<Alert
							setAlertState={() => {
								dispatch(paymentActions.setPaymentError(false));
							}}
						>
							{payReqErrMsg}
						</Alert>
					</div>
				</Transition>
			</div>
		</div>
	);
};

export function renderSearchOptions(options) {
	return options.map((option) => (
		<option key={option.key} value={option.key}>
			By {option.text}
		</option>
	));
}

export default SearchBar;
