import { HEADER_X_API_KEY } from '../util/strings';

const prepareQuery = (obj) => {
  let returnArray = [];
  for (const key in obj) {
    if (obj[key] && obj[key].length > 0) {
      returnArray.push({ [key]: obj[key] });
    }
  }
  return returnArray;
}

const isNullish = function (obj) {
  let everythingIsNull = Object.values(obj).every(value => {
    if (value === null || value === undefined) {
      return true;
    }

    return false;
  });
  return everythingIsNull;
}

const customersService = {
  fetchCustomers: async (idToken, searchQuery) => {
    let url = `${process.env.REACT_APP_API_ENDPOINT_BASE}/customers`;
    if (!isNullish(searchQuery)) {
      let queryString = '?';
      const cleanQuery = prepareQuery(searchQuery);
      if (cleanQuery && cleanQuery.length) {
        cleanQuery.forEach((queryParam, idx) => {
          let theKey = Object.keys(queryParam);
          if (idx > 0) {
            queryString += `&`;
          }
          queryString += `${theKey}=${queryParam[theKey]}`;
        });
      }
      url += `${queryString}`;
    }

    const res = await fetch(url, {
      headers: {
        [HEADER_X_API_KEY]: idToken.idToken,
      },
    });

    return res.json();
  },
  fetchMoreCustomers: async (
    idToken,
    nextPageNumber,
    searchQuery,
  ) => {
    let url = `${process.env.REACT_APP_API_ENDPOINT_BASE}/customers?page=${nextPageNumber}`;
    if (!isNullish(searchQuery)) {
      const cleanQuery = prepareQuery(searchQuery);
      let queryString = `&`;
      if (cleanQuery && cleanQuery.length) {
        cleanQuery.forEach((queryParam, idx) => {
          let theKey = Object.keys(queryParam);
          if (idx > 0) {
            queryString += `&`;
          }
          queryString += `${theKey}=${queryParam[theKey]}`;
        });
      }
      url += `${queryString}`;
    }

    const res = await fetch(url, {
      headers: {
        [HEADER_X_API_KEY]: idToken.idToken,
      },
    });

    return res.json();
  },
};

export default customersService;
