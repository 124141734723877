import { useOktaAuth } from '@okta/okta-react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { fetchItems, fetchMoreItems, updateSearchQuery } from '../itemsState';
import ListItem, { ListItemSkeleton } from '../ListItem/ListItem';
import {
	views,
	selectInventoryByItemView,
	setShowPatternAlert,
	setShowCharCountAlert,
	clearAlerts,
	setSearch,
} from '../../../SearchBar/searchBarState';
import useIntersectionObserver from '../../../../util/intersectionObserver';
import SearchBar from '../../../SearchBar/SearchBar';
import { COMMON_QUERY_PARAM_OPTIONS } from '../../../../util/constants';

const ItemsList = () => {
	const { authState } = useOktaAuth();
	const { idToken } = authState;
	const dispatch = useDispatch();
	const items = useSelector((state) => state.items.items);
	const itemsMsg = useSelector((state) => state.items.itemsMsg);
	const isItemsLoading = useSelector((state) => state.items.isItemsLoading);
	const isMoreItemsLoading = useSelector(
		(state) => state.items.isMoreItemsLoading
	);
	const currentView = useSelector((state) => state.searchBar.view);

	//Renders all items and removes the null items we receive from the db.
	const inventoryByItemListItems = items?.results?.map((item, idx) =>
		item.name !== null || item.retail_name !== null ? (
			<ListItem item={item} key={`${item.id}-${idx}`} />
		) : null
	);

	const renderSkeletonItems = (x) => {
		let skeletonItems = [];

		for (let i = 0; i < x; i++) {
			skeletonItems.push(<ListItemSkeleton key={i} />);
		}
		return skeletonItems;
	};

	const handleInventoryByItemsSearch = (e) => {
		const str = e.target.value;
		dispatch(clearAlerts());
		if (handleInventoryByItemsSearch.validateInput(str)) {
			handleInventoryByItemsSearch.handleDispatch(str);
		}
	};

	//Tests if search in put is less than 70 characters long, and makes sure only '-', '_' and '$' special characters are allowed.
	handleInventoryByItemsSearch.validateInput = (str) => {
		const pattern = /[^\w\s\b$-]/g;
		if (str.length > 0 && str.length <= 70) {
			if (pattern.test(str)) {
				dispatch(setShowPatternAlert(true));
				return false;
			}
		} else if (str.length > 70) {
			dispatch(setShowCharCountAlert(true));
			return false;
		}
		return true;
	};

	handleInventoryByItemsSearch.handleDispatch = (str) => {
		if (str === '') {
			dispatch(
				updateSearchQuery({
					idToken: authState?.idToken,
					searchQuery: '',
				})
			);
		} else {
			dispatch(
				updateSearchQuery({
					idToken: authState?.idToken,
					searchQuery: str,
				})
			);
		}
	};

	//fetch items on componentMount
	useEffect(() => {
		if (currentView !== views.INVENTORY_BY_ITEM) {
			dispatch(selectInventoryByItemView());
			dispatch(setSearch(''));
		}
	}, [dispatch, currentView]);

	useEffect(() => {
		dispatch(fetchItems(idToken));

		return () => {
			dispatch(updateSearchQuery(''));
		};
	}, [dispatch, idToken]);

	useIntersectionObserver({
		target: '.endOfItemsList',
		handleInViewPort: () => dispatch(fetchMoreItems(idToken)),
	});

	return (
		<div className='overflow-x-hidden'>
			<div className='mx-2'>
				<SearchBar
					handleSearch={handleInventoryByItemsSearch}
					placeholder='Search Items...'
					queryParamOptions={COMMON_QUERY_PARAM_OPTIONS}
				/>
			</div>
			<div className='flex flex-col'>
				<div className='-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8'>
					<div className='py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8'>
						<div className='shadow overflow-hidden border-b border-gray-200'>
							<table className='min-w-full divide-y divide-gray-200'>
								<thead className='bg-gray-50 font-sans'>
									<tr>
										<th
											scope='col'
											className='px-6 py-3 text-left text-xs font-light text-gray-500 uppercase tracking-wider'
										>
											Product Name
										</th>
										<th
											scope='col'
											className='px-6 py-3 text-center text-xs font-light text-gray-500 uppercase tracking-wider'
										>
											Brand
										</th>
										<th
											scope='col'
											className='pl-6 py-3 text-xs font-light text-gray-500 uppercase tracking-wider text-left'
										>
											Retail Name
										</th>
										<th
											scope='col'
											className='pr-6 py-3 text-xs font-light text-gray-500 uppercase tracking-wider text-right'
										>
											Item Details
										</th>
									</tr>
								</thead>
								<tbody className='bg-white divide-y divide-gray-200'>
									{isItemsLoading
										? renderSkeletonItems(30)
										: inventoryByItemListItems}
									{isMoreItemsLoading ? renderSkeletonItems(3) : null}
								</tbody>
							</table>
						</div>
						<div className='mt-5 text-center'>
							<div className='endOfItemsList mt-5 text-center text-gray-500 uppercase'>
								{itemsMsg}...
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ItemsList;
