const Card = ({ title, children, styles }) => {
	return (
		<div className={`border border-gray-200 rounded-md shadow-md ${styles}`}>
			<div className='text-base border-b border-gray-200 text-center mx-auto bg-gray-50'>
				<h5 className='text-center font-medium text-gray-500 uppercase tracking-wider py-2'>
					{title}
				</h5>
			</div>
			{children}
		</div>
	);
};

export default Card;
