import { useOktaAuth } from '@okta/okta-react';
import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import useIntersectionObserver from '../../util/intersectionObserver';
import CustomerModal from './CustomerModal';
import CompoundSearch from '../../components/CompoundSearch';
import CustomerList from './customerList';

import { fetchCustomers, fetchMoreCustomers, clearSearch, searchFields, displayFields, setSearch, setCustomerMsg } from './customersState';
/* This example requires Tailwind CSS v2.0+ */

export default function Customers() {
  const { authState } = useOktaAuth();
  const { idToken } = authState;
  const tableHeaders = displayFields.map((field) => field.name);
  const customers = useSelector((state) => state.customers.customers);
  const customersLoading = useSelector((state) => state.customers.customersLoading);
  const customerMsg = useSelector((state) => state.customers.customerMsg);
  const dispatch = useDispatch();
  const customerPageCount = useSelector(
    (state) => state.customers.customers?.meta?.next_page
  );
  const [modalCustomer, setModalCustomer] = useState(null);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    //Prevents refetching (and resetting the customers list) if the customers list state is already populated.
    if (customers?.results.length <= 0 && customerMsg !== 'No results') {
      dispatch(fetchCustomers(idToken));
    }
  }, [dispatch, idToken, customers, customerMsg]);

  useIntersectionObserver({
    target: '.endOfCustomersList',
    handleInViewPort: () => {
      if (customerPageCount === null) {
        dispatch(setCustomerMsg('NO MORE RESULTS'));
      } else {
        dispatch(fetchMoreCustomers(idToken));
      }
    },
  });

  const renderModal = (customer) => {
    setShowModal(true);
    setModalCustomer(customer);
  };

  function handleInput(inputName, inputValue) {
    dispatch(setSearch({ [inputName]: inputValue }))
  }

  function handleClick(action) {
    switch (action) {
      case 'search':
        dispatch(fetchCustomers(idToken));
        break;
      case 'clear':
        dispatch(clearSearch());
        dispatch(fetchCustomers(idToken));
        break
      default:
        dispatch(fetchCustomers(idToken));
        break;
    }
  }

  function Table({ headers, results }) {
    return (
      <div className='flex flex-col'>
        {showModal && (
          <CustomerModal
            customer={modalCustomer}
            showModal={showModal}
            setShowModal={setShowModal}
            setModalCustomer={setModalCustomer}
          />
        )}
        <div className='-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8'>
          <div className='py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8'>
            <div className='shadow overflow-hidden border-b border-gray-200'>
              <table className='min-w-full divide-y divide-gray-200'>
                <thead className='bg-gray-50'>
                  <tr>
                    {headers.map((title, idx) => (
                      <th
                        key={idx + 1}
                        scope='col'
                        className='px-6 py-3 text-xs font-medium text-gray-500 uppercase tracking-wider text-center'
                      >
                        {title}
                      </th>
                    ))}

                    <th scope='col' className='relative px-6 py-3'>
                      <span className='sr-only'>Edit</span>
                    </th>
                  </tr>
                </thead>
                <tbody className='bg-white divide-y divide-gray-200 overflow-y-scroll'>
                  {
                    results.map((customer, idx) => (
                      <CustomerList data={customer} key={idx + 1} renderModal={() => renderModal(customer)} />
                    ))
                  }
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    );
  }

  let displayedContent;
  if (customers?.results.length > 0) {
    displayedContent = <Table headers={tableHeaders} results={customers.results}></Table>
  } else if (customerMsg !== 'No results' && customersLoading) {
    displayedContent = <h3 className='mt-5 ml-5 mr-5 mb-5'>Loading...</h3>
  }

  return (
    <div className='overflow-x-clip'>
      <div>
        <div className='px-4 bg-white mb-6'>
          <div className='sm:px-0 font-sans'>
            <h3 className='md:text-xl font-medium text-gray-900'>Customers</h3>
            <p className='mt-1 text-sm text-gray-500'>
              Use the search tool below to search customers using any of the
              field below. Click on any item to for more details.
            </p>
          </div>
        </div>

        <CompoundSearch
          fields={searchFields}
          handleInput={handleInput}
          handleClick={handleClick}
        />

        <div className=''>
          {displayedContent}
        </div>
        <div className='endOfCustomersList'>
          <div className='mt-5 text-center text-gray-500 uppercase'>
            {customerMsg}
          </div>
        </div>
      </div>
    </div>
  );
}
