import { HEADER_X_API_KEY } from '../util/strings';

const itemsService = {
	fetchItems: async (idToken, searchQuery, searchQueryParam) => {
		let url = `${process.env.REACT_APP_API_ENDPOINT_BASE}/items`;
		if (searchQuery?.length) {
			url += `?${searchQueryParam}=${searchQuery}`;
		}

		const res = await fetch(url, {
			headers: {
				[HEADER_X_API_KEY]: idToken.idToken,
			},
		});

		return await res.json();
	},
	fetchMoreItems: async (
		idToken,
		nextPageNumber,
		searchQuery,
		searchQueryParam
	) => {
		let url = `${process.env.REACT_APP_API_ENDPOINT_BASE}/items?page=${nextPageNumber}`;
		if (searchQuery?.length) {
			url += `&${searchQueryParam}=${searchQuery}`;
		}

		const res = await fetch(url, {
			headers: {
				[HEADER_X_API_KEY]: idToken.idToken,
			},
		});

		return await res.json();
	},
};

export default itemsService;
