import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import printOrdersService from '../../services/printOrders';

export const initialState = {
	showSuccessAlert: false,
	showErrorAlert: false,
	response: '',
	orderID: '',
	orderLoading: false,
};

export const fetchPrintOrder = createAsyncThunk(
	'printOrders/reprintOrder',
	async ({ idToken, orderId }) => {
		return await printOrdersService.printOrders(orderId, idToken);
	}
);

export const printOrdersSlice = createSlice({
	name: 'printOrders',
	initialState,
	reducers: {
		setShowSuccessAlert: (state, action) => {
			state.showSuccessAlert = action.payload;
		},
		setShowErrorAlert: (state, action) => {
			state.showErrorAlert = action.payload || false;
		},
		setOrderID: (state, action) => {
			state.orderID = action.payload;
		},
		setOrderLoading: (state, action) => {
			state.orderLoading = action.payload;
		},
		setResponse: (state, action) => {
			state.response = action.payload;
		},
		clearAlerts: (state) => {
			state.showSuccessAlert = false;
			state.showErrorAlert = false;
		},
	},
	extraReducers: (builder) => {
		builder.addCase(fetchPrintOrder.pending, (state, action) => {
			state.orderLoading = true;
		});
		builder.addCase(fetchPrintOrder.rejected, (state, action) => {
			state.response =
				action?.error?.message || 'Request Failed. Please try again.';
			state.orderLoading = false;
			state.showErrorAlert = true;
		});
		builder.addCase(fetchPrintOrder.fulfilled, (state, action) => {
			const { message, status } = action.payload;
			state.orderLoading = false;
			state.response = message;
			//TODO get api changed to include found,not found status
			if (status === 200 && message.includes('printed successfully')) {
				state.showSuccessAlert = true;
			} else {
				state.showErrorAlert = true;
			}
		});
	},
});

export const {
	setShowSuccessAlert,
	setShowErrorAlert,
	setOrderID,
	setOrderLoading,
	setResponse,
	clearAlerts,
} = printOrdersSlice.actions;

export default printOrdersSlice.reducer;
